import React, { useContext, useEffect, useState } from "react";
import _axios from "../axios";
import UserSidebar from "../components/UserSidebar";
import UserSidebarMobile from "../components/UserSidebarMobile";
import SingleProduct from "../components/SingleProduct";
import { AccountContext } from "../contexts/AccountContext";
import buildLink from "../urls";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { slugify } from "../Utils";
import useDeviceSize from "../components/useDeviceSize";
import NoData from "../components/NoData";
import { HandleAuthForm } from "../components/HandleAuthForm";


function Follow() {
  const [data, setData] = useState();
  const [page, setPage] = useState(1);
  const [showLimit, setShowLimit] = useState(false);
  const [limit, setLimit] = useState(48);
  const [mobileSort, showMobileSort] = useState(false);
  const [total, setTotal] = useState(0);
  const [state, dispatch] = useContext(AccountContext);
  const [loading, setLoading] = useState(true);
  const [follow, setFollow] = useState(false);
  const [width, height] = useDeviceSize();
  const { openAuthForm } = HandleAuthForm();
const navigate = useNavigate()
  function pageSetter(page) {
    setPage(page["selected"] + 1);
    navigate(
      `/account/buyagain&page=${page["selected"] + 1}&limit=${limit}`
    );
  }

  function followAction(id) {
    if (!state.loged) {
      openAuthForm(0);
    }
    var obj = {
      type: "seller",
      type_id: id
    };

    _axios
      .post(
        buildLink("follow", undefined, undefined, window.location.host),
        obj
      )
      .then((resp) => {
        if (!resp.data.data.follow) {
          setFollow(true);
        }
      });
  }

  function limitSetter(lim) {
    setLimit(lim);
    navigate({
      pathname: "/account/buyagain",
      search: `&page=${page}&limit=${lim}`
    });
  }

  useEffect(() => {
    if (state.loged) {
      navigate("/");
      setLoading(false);
    } else if (state.loged) {
      _axios
        .get(
          buildLink(
            "follow",
            undefined,
            window.innerWidth,
            window.location.host
          )
        )
        .then((response) => {
          if (response?.data?.success) {
            const data = response?.data;
            setData(data);
            setTotal(data?.length);
            setLoading(false);
            if (!state.loged) {
            }
          } else {
            setLoading(false);
          }
        });
    }
  }, [state, follow]);

  return (
    <div className="container text-dblack">
      <Helmet>
        <title>My Account | ishtari</title>
      </Helmet>
      <div className="pb-2">
        <div className="flex-row md:flex">
          <div className="w-full mb-3 md:w-1/5">
            {width > 650 ? (
              <UserSidebar active={"follow"} />
            ) : (
              <UserSidebarMobile active={"follow"} />
            )}
          </div>
          {loading ? (
            <div className="flex justify-center w-full">
              <div className="animate-pulse h-10 w-10 bg-dgrey1 rounded-full"></div>
            </div>
          ) : (
            <div className="w-full px-2 md:w-4/5 md:pl-8 mb-8">
              <div className="flex justify-between">
                <p className="text-lg pr-semibold py-4">Following </p>
                {!data?.data?.followed && <NoData />}
              {data?.data?.followed && (
                <div className="flex flex-col grid-3">
                  {data?.data?.followed?.map((item) => (
                    <div className=" w-full  flex flex-row justify-between items-center bg-white p-5 text-d14 mobile:text-d16 my-1">
                      {" "}
                      <Link href={`/${slugify(item.name)}/s=${item.seller_id}`}>
                        <div className="flex justify-center items-center">
                          <div className=" border rounded-full flex justify-center items-center p-4 w-16 h-16   border-dgrey1 border-opacity-50 font-semibold text-d18">
                            {item.image ? (
                              <img
                                src={item.image}
                                alt={item.name}
                                width="100"
                                height="100"
                              />
                            ) : (
                              "S"
                            )}
                          </div>{" "}
                          <div className="mx-5 uppercase">{item.name}</div>
                        </div>
                      </Link>
                      <div
                        className="flex justify-center bg-dbase pr-semibold text-white px-4  rounded-full items-center h-10 cursor-pointer"
                        onClick={() => followAction(item?.seller_id)}
                      >
                        following
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {data?.data?.followed && (
                <div className="w-full mt-5 py-5 text-d28">Products</div>
              )}
              <div className="grid grid-cols-2  lg:grid-cols-6 gap-2 ">
                {data?.data?.products?.map((item) => (
                  <SingleProduct item={item} key={item.product_id} />
                ))}
              </div>
            </div>
          
        </div>
      
                )}</div>
                </div>
                </div>)}
                
export default Follow;
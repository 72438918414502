import React, { useState, useEffect, useContext } from 'react';
import _axios from '../axios';
import UserSidebar from '../components/UserSidebar';
import UserSidebarMobile from '../components/UserSidebarMobile';
import PointsLoader from '../components/PointsLoader';
import { FaWallet } from 'react-icons/fa';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import ReactPaginate from 'react-paginate';
import { AccountContext } from '../contexts/AccountContext';
import buildLink from '../urls';
import { Navigate, Outlet, unstable_HistoryRouter, useNavigate } from 'react-router-dom';

const Wallet = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [state, setState] = useContext(AccountContext);
  const [balance, setBalance] = useState(0);
  const [transactionData, setTransactionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const limit = 10;
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {

     
      _axios
        .get(buildLink('getBalance', undefined, width))
        .then((response) => {
          if (response.data.success) {
            setBalance(response.data.data.balance);
          } else {
            // Handle error 
          }
        })
        .catch((error) => {
          // Handle error 
          console.error('Error fetching balance:', error);
        });
    
  }, [state.logged]);
  
  

  useEffect(() => {
    _axios
     .get(
        buildLink('getTransactionHistory', undefined, width) +
          '&limit=' +
          limit +
          '&page=' +
          page
      )
     .then((response) => {
        if (response.data.success) {
          setTransactionData(response.data.data);
          setLoading(false);
        }
      });
  }, [page]);

  const pageSetter = (page) => {
    setPage(page.selected + 1);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="text-dblack container">
      <div className="pb-2">
        <div className="flex-row md:flex gap-3">
        <div className={`w-full mb-4 md:w-[25%]  -ml-3 ${width <= 650 ? 'hidden' : ''}`}>
            <UserSidebar active={"wallet"} />
          </div>
          <div className="w-full md:w-4/5 px-2 md:px-0  mb-5">
            {loading ? (
              <div className="flex justify-center w-full">
                <PointsLoader />
              </div>
            ) : (
              <div className="w-full md:mx-auto">
                <div
                  className="w-full h-56 md:h-44 flex flex-col gap-3 md:gap-0 md:flex-row justify-between items-start md:items-center p-5"
                  style={{ backgroundColor: '#59acf4' }}
                >
                  <div className="flex flex-col">
                    <div
                      className="rounded-full w-max p-5 pr-semibold"
                      style={{ backgroundColor: '#a8e8ff' }}
                    >
                      {state.firstname
                       ?.replace(/\s+/g, '')
                       .charAt(0)
                       .toUpperCase()}{' '}
                      {state.lastname
                       ?.replace(/\s+/g, '')
                       .charAt(0)
                       .toUpperCase()}
                    </div>
                    <div className="text-white">
                      {' '}
                      {state.firstname} {state.lastname}{' '}
                    </div>
                  </div>
                  <div
                    className="flex justify-between items-center p-3 text-white w-full md:w-2/5 lg:w-1/3"
                    style={{ backgroundColor: '#ffffff4a' }}
                  >
                    <div className="pr-semibold">
                      <div>Available balance:</div>
                      <div className="text-d20">{balance}</div>
                    </div>
                    <FaWallet className="w-6 h-6 md:w-8 md:h-8" />
                  </div>
                </div>

                <div className="py-4 md:pl-6">
                  {transactionData?.data_transactions?.map((data) => (
                    <div
                      key={data.transaction_id}
                      className="bg-white rounded-md shadow-md mb-3 p-4"
                    >
                      <div className="flex items-center justify-between mb-3">
                        <div className="flex items-center gap-2">
                          <div
                            className="rounded-full p-4 text-sm"
                            style={{ backgroundColor: '#a8e8ff' }}
                          >
                            {state.firstname
                             ?.replace(/\s+/g, '')
                             .charAt(0)
                             .toUpperCase()}{' '}
                            {state.lastname
                              ?.replace(/\s+/g, '')
                             .charAt(0)
                             .toUpperCase()}{" "}
                          </div>
                          <div>
                            <div>
                              {" "}
                              {state.firstname} {state.lastname}{" "}
                            </div>
                            <div className="text-sm text-dgreyQtyProduct">
                              {data.date}
                            </div>
                          </div>
                        </div>
                        <div className="pr-semibold text-dgreyProduct">
                          {data.type}
                        </div>
                      </div>
                      <div>
                        Amount:{' '}
                        <span
                          className={` ${
                            data.isDeposit ? 'text-dgreen' : 'text-dbase'
                          }`}
                        >
                          {data.amount}
                        </span>{' '}
                      </div>
                      {data.description.length > 0 && (
                        <div>
                          Description:{' '}
                          <span className="text-dgreyProduct">
                            {data.description}
                          </span>
                        </div>
                      )}
                    </div>
                  ))}

                  <div className="pagination-div pt-4">
                    {!loading &&
                      transactionData?.total_transactions > limit && (
                        <ReactPaginate
                          pageCount={Math.ceil(
                            transactionData?.total_transactions /
                              transactionData?.total_pages
                          )}
                          containerClassName={"category-pagination"}
                          onPageChange={pageSetter}
                          pageRangeDisplayed={width > 650 ? 2 : 1}
                          marginPagesDisplayed={width > 650 ? 1 : 1}
                          previousLabel={<IoIosArrowBack />}
                          previousLinkClassName={"arrowLink"}
                          nextLinkClassName={"arrowLink"}
                          nextLabel={<IoIosArrowForward />}
                          activeClassName={"active-pagination-category"}
                          forcePage={page - 1}
                        ></ReactPaginate>
                      )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
import { useRef, useState } from "react";
import { useContext, useEffect, useCallback } from "react";
import _axios from "../axios";
import SingleProducts from "../components/SingleProduct";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import ExpressImage from "../assets/images/express.png";
import Slider from "react-slick";
import buildLink, { path, pixelID } from "../urls";
// import image1 from '../assets/images/1.png'
// import image2 from '../assets/images/2.png'
import ProductZoom from "../components/ProductZoom";
import PointsLoader from "../components/PointsLoader";
import queryString from "query-string";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CartContext } from "../contexts/CartContext";
import { AccountContext } from "../contexts/AccountContext";
import SeoHandler from "../components/SeoHandler";
import { WishlistContext } from "../contexts/WishlistContext";
import NewZoom from "../components/NewZoom";
import ReactPixel from "react-facebook-pixel";
import { useWindowDimensions } from "../components/TopHeader";
import Timer from "../components/Timer";
import NotifyMe from "../components/NotifyMe";
import StarRatings from "react-star-ratings";
import reviewimg from "../assets/images/review.png";
import reviewimg1 from "../assets/images/review-1.png";
import Cookies from "js-cookie";
import { LoadingContext } from "react-router-loading";
import { useProductContext } from "../contexts/ProductContext";
import { CurrencyContext } from "../contexts/CurrencyContext";
import ProductPlaceholder from "./ProductPlaceholder";
import Share from "../components/Share";
import { BsChevronDown, BsPlusLg } from "react-icons/bs";
import { FaStar, FaTrash } from "react-icons/fa";
import ProductOptionModal from "../components/ProductOptionModal";
import SingleProductNew from "../components/SingleProductNew";
import notFound from "../assets/images/page-404.webp";
import DOMPurify from "dompurify";
import ReactPaginate from "react-paginate";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { slugify } from "../Utils";
import { AiFillStar, AiOutlineCheck, AiOutlineLike } from "react-icons/ai";
import axios from "axios";
import imageCompression from "../assets/images/review.empty.png";
import { HandleAuthForm } from "../components/HandleAuthForm";
import Loader from "../components/Loader";
import { HiOutlineMail } from "react-icons/hi";
// 38792 - OUT OF STOCK ID
function Productv2(props) {
  // const { HandleAuthForm } = HandleAuthForm()
  const history = useNavigate();
  const location = useLocation();
  const [accoutState] = useContext(AccountContext);
  const [additionalData, setAdditionalData] = useState([]);
  const [purchased, setPurchased] = useState();
  const [showReviewFiltersStars, setShowReviewFiltersStars] = useState(false);
  const [reviewFilter, setReviewFilter] = useState({
    text: "Most Recent",
    value: "r.review_id-DESC",
  });
  const [ReviewImages, setReviewImages] = useState([]);
  const [showReviewFilters, setShowReviewFilters] = useState(false);
  const [selectedReviewImg, setSelectedReviewImg] = useState("");
  const [selectedReviewImgIndex, setSelectedReviewImgIndex] = useState(0);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [selectedReview, setSelectedReview] = useState([]);
  const [exceededSizeLimitErr, setExceedSizeLimitErr] = useState(false);
  const [likeLoading, setLikeLoading] = useState({
    bool: false,
    id: 0,
  });
  const [likedReviews, setLikedReviews] = useState([]);
  const [alreadyLiked, setAlreadyLiked] = useState([]);
  const [likeCounts, setLikeCounts] = useState({});
  const [ratingCustomer, setRatingCustomer] = useState(0);

  const [isDetails, setIsDetails] = useState(false);
  const [state, dispatch] = useContext(CartContext);
  const [stateW, dispatchW] = useContext(WishlistContext);
  const [accountstate] = useContext(AccountContext);
  const [productData, setProductData] = useState({});
  const [productData2, setProductData2] = useState({});

  const [images, setImages] = useState([]);
  const [successAdded, setSuccessAdded] = useState(false);
  const [hasOption, setHasOption] = useState(false);
  const [hasAddToCartError, setHasAddToCartError] = useState(false);
  const [AddToCartError, setAddToCartError] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [noProductData, setNoProductData] = useState(false);
  const [activeImage, setActiveImage] = useState({});
  const [showNotify, setShowNotify] = useState(false);
  const [activeOption, setActiveOption] = useState({});
  const [activeImageOption, setImageActiveOption] = useState({});
  const [addingToCart, setAddingToCart] = useState(false);
  const [optionParent, setOptionParent] = useState("");
  const [countDown, setCountDown] = useState();
  const [countDownPointer, setCountDonwPointer] = useState();
  const [newSizeGuide, setShowNewSizeGuide] = useState();
  const [showReview, setShowReview] = useState(false);
  const [viewColor, setViewColor] = useState();
  const [viewSeriesVal, setViewSeriesVal] = useState();
  const [colorSelected, setColorSelected] = useState();
  const [seriesOpSelected, setSeriesOpSelected] = useState();
  const [reviews, setReviews] = useState();
  const [review, setReview] = useState();
  const [value, setValue] = useState(0);
  const [checked, setChecked] = useState(["0"]);
  var product_id = useParams().id.split("=")[1];
  product_id = props.id ? props.id : product_id;
  const [stateAccount, dispatchAccount] = useContext(AccountContext);
  const [required, setRequired] = useState();
  const [loading, setLoading] = useState(false);
  const loadingContext = useContext(LoadingContext);
  const curr = useContext(CurrencyContext);
  const [part, setPart2] = useState(false);
  const [hovered, setHovered] = useState(false);
  // const product = props.location.product
  const { width } = useWindowDimensions();
  const [isWishlist, setIsWishlist] = useState(false);
  const [hasBannerEvent, setHasBannerEvent] = useState();
  const textRef = useRef();
  const commentRef = useRef();
  const { productHolder } = useProductContext();
  const { setProductNotFoundd } = useProductContext();
  const [showShare, setShowShare] = useState(false);
  const [nonrefundable, setNonrefundable] = useState(false);
  const [returnPolicy, setReturnPolicy] = useState([]);
  // const [likedData, setLikedData] = useState([]);
  const [imagess, setImagess] = useState([]);
  const hiddenFileInput = useRef(null);
  const [exceededMaxnb, setExceededMaxNb] = useState(false);
  const [pageValue, setPageValue] = useState(1);
  const [scroll, setScroll] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showGroup, setShowGroup] = useState(false);
  const [GroupWishlist, setGroupsWishlist] = useState([]);
  const source_type = location?.state?.source_type;
  const source_type_id = location?.state?.source_type_id;
  const banner_image_id = location?.state?.banner_image_id;
  const { openAuthForm, closeAuthForm } = HandleAuthForm();

  const [replyTexts, setReplyTexts] = useState({});
  const handleReplyTextChange = (review_id, text) => {
    setReplyTexts((prev) => ({ ...prev, [review_id]: text }));
  };
  const [loadingReplies, setLoadingReplies] = useState({});

  // const [errorSizeMsg, setErrorSizeMsg] = useState();
  const [showOptionModal, setShowOptionModal] = useState({
    show: false,
    bundle: null
  });
  const [nameValue, setName] = useState("");
  const [descriptionValue, setDescription] = useState("");
  const [result, setResult] = useState();
  const [showModel, setShowModel] = useState(false);

  const [bundles, setProductBundle] = useState();

  const handleImageUpload = (event) => {
    hiddenFileInput.current.click();
  };

  const productBundlesSetting = {
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    prevArrow: <CustomPrevArrows direction={"l"} />,
    nextArrow: <CustomNextArrows direction={"r"} />
  };
  const [showReplyInputFor, setShowReplyInputFor] = useState(null);
  const dataInformation = useContext(CurrencyContext);
  const [replyText, setReplyText] = useState('');
  const [loadingReply, setLoadingReply] = useState(false);
  const [loadingreply, setLoadingreply] = useState(false);

  const handleShowReply = (review_id) => {
    setShowReplyInputFor(review_id);
    setReplyText('');  // Clear the reply text when showing the input
  };

  // const handleReplySubmit = async (review_id) => {
  //   const userId = Cookies.get('user_id');

  //   if (!userId) {
  //     console.error("User ID not found in cookies.");
  //     return;
  //   }

  //   if (replyText.trim()) {
  //     const body = {
  //       review_id: review_id,
  //       user_id: userId,
  //       comment: replyText.trim(),
  //     };

  //     try {
  //       setLoadingReply(true);

  //       const response = await _axios.post(
  //         buildLink("reviewsreply", undefined, window.innerWidth, window.config["site-url"]),
  //         body
  //       );

  //       console.log("Response:", response);

  //       setShowReplyInputFor(null); 
  //       setLoadingReply(false); 
  //       window.location.reload();

  //     } catch (error) {
  //       console.error("Error submitting reply:", error);

  //       const errorMessage = error.response ? error.response.data.message : "An error occurred.";
  //       alert(errorMessage);  

  //       setLoadingReply(false);
  //     }
  //   }
  // };

  const handleReplySubmit = async (review_id) => {
    const userId = Cookies.get('user_id');

    if (!userId) {
      console.error("User ID not found in cookies.");
      return;
    }

    if (replyText.trim()) {
      const body = {
        review_id: review_id,
        user_id: userId,
        comment: replyText.trim(),
      };

      try {
        setLoadingreply(true);
        const response = await _axios.post(
          buildLink("reviewsreply", undefined, window.innerWidth, window.config["site-url"]),
          body
        );

        setShowReplyInputFor(null); 
        setLoadingReply(false); 
        window.location.reload();

      } catch (error) {
        console.error("Error submitting reply:", error);

        const errorMessage = error.response ? error.response.data.message : "An error occurred.";
        alert(errorMessage);  

        setLoadingReply(false);

      }
    }
  };


  async function handleDeleteReply(replyId) {
    try {
      setLoadingreply(true);

      const url = buildLink(
        "reviewsreply",
        undefined,
        window.innerWidth,
        `${window.config["site-url"]}`
      ) + `&review_comment_id=${replyId}`;

      const response = await _axios.delete(url);

      if (response && response.data) {
        console.log(`Reply ${replyId} deleted successfully`);
        window.location.reload();
      }
    } catch (error) {
      console.error("Error deleting reply:", error);

      const errorMessage = error.response ? error.response.data.message : "An error occurred.";
    } finally {
      setLoadingreply(false);
    }
  }
  const [replies, setReplies] = useState([]);
  const [pagereply, setpagereply] = useState(1);

  async function showMoreReplies(review_id) {
    try {
      setLoadingreply(true); // Set loading state to true
      const url = buildLink(
        "reviewsreply",
        undefined,
        window.innerWidth,
        `${window.config["site-url"]}`
      ) + `&review_id=${review_id}&page=${pagereply}`;

      const response = await _axios.get(url);

      if (response && response.data) {
        console.log(response);

        setShowReplyInputFor(false);
        setpagereply(response.data.data.next_page);
        setReplies(response.data.data.replies);
        console.log("Bacharrrr", response.data.data.replies);
      }

    } catch (error) {
      console.error("Error submitting reply:", error);

      const errorMessage = error.response ? error.response.data.message : "An error occurred.";

    } finally {
      setLoadingreply(false);
    }
  }


  function DotsLoader() {
    return (
      <div className="loading">
        <span className="loading__dot"></span>
        <span className="loading__dot"></span>
        <span className="loading__dot"></span>
      </div>
    );
  }

  function changeRating(newRating, name) {
    // console.log(newRating);
    setRatingCustomer(newRating);
  }


  


  const {
    data,
 
  } = props; //data is for product part one data




  function sanitizeHTML(html) {
    if (typeof window !== "undefined") {
      return DOMPurify.sanitize(html);
    } else {
      return html;
    }
  }

  function handleReviewsModal(img, index, review) {
    setSelectedReviewImg(img);
    setSelectedReviewImgIndex(index);
    setShowReviewModal(true);
    setSelectedReview(review);
    const htmlElement = document.querySelector("html");
    const bodyElement = document.querySelector("body");

    // Add a CSS class to remove the overflow-y
    htmlElement.classList.add("popup-open");
    bodyElement.classList.add("popup-open");
  }


  const handleReviewLike = (review_id) => {
    if (stateAccount.loged) {
      setLikeLoading({
        bool: true,
        id: review_id,
      });
      _axios
        .post(buildLink("likeUnlikeReview", undefined, undefined) + review_id)
        .then((response) => {
          // console.log(response.data);
          if (response.data.success && response.data.data.liked) {
            setLikedReviews((current) => [...current, review_id]);
            setAlreadyLiked((current) => [...current, review_id]);
          } else {
            setLikedReviews((current) =>
              current.filter((liked) => {
                return liked !== review_id;
              })
            );
            setAlreadyLiked((current) =>
              current.filter((liked) => {
                return liked !== review_id;
              })
            );
          }
          setLikeCounts((prevLikeCounts) => ({
            ...prevLikeCounts,
            [review_id]: response.data.data.nb_likes,
          }));
          setLikeLoading({
            bool: false,
            id: 0,
          });
        });
    } else {
      HandleAuthForm()
    }
  };











  const titleRef = useRef();
  const handleClick = () => {
    if (titleRef.current) {
      titleRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error('Target element for scrolling not found');
    }
  };

  useEffect(() => {
    // Log the ref to ensure it's being set
    console.log('titleRef:', titleRef.current);
  }, []);

  function compressFile(imageFile, options = defaultOptions) {
    return imageCompression(imageFile, options);
  }
  
  const defaultOptions = {
    maxSizeMB: 1,
  };

  async function onFileChange(event) {
    const selectedFiles = event.target.files;
    const compressedImages = [];
    if (event.target.files.length === 5) {
      for (const image of selectedFiles) {
        try {
          const compressedImageFile = await compressFile(image);
          var file = new File([compressedImageFile], image.name);
          compressedImages.push(file);
        } catch (error) {
          console.error("Error compressing image:", error);
        }
      }

      // setReviewImages([...event.target.files]);
      setReviewImages(compressedImages);
      setExceededMaxNb(false);
    } else if (event.target.files.length > 5) {
      for (let i = 0; i < 5; i++) {
        try {
          const compressedImageFile = await compressFile(event.target.files[i]);
          var file = new File(
            [compressedImageFile],
            event.target.files[i].name
          );
          compressedImages.push(file);
        } catch (error) {
          console.error("Error compressing image:", error);
        }
      }
      // ReviewImages.push(event.target.files[i]);
      setReviewImages(compressedImages);
      setExceededMaxNb(true);
      setTimeout(() => {
        setExceededMaxNb(false);
      }, 3500);
    } else {
      setExceededMaxNb(false);

      for (const image of selectedFiles) {
        try {
          const compressedImageFile = await compressFile(image);
          var file = new File([compressedImageFile], image.name);
          compressedImages.push(file);
        } catch (error) {
          console.error("Error compressing image:", error);
        }
      }

      setReviewImages([...ReviewImages, ...compressedImages]);
    }
  }

  if (imagess?.length > 5) {
    setImagess(imagess.slice(0, 5));
    //console.log(imagess);
  }

  const checkIfLogged = () => {
    if (accoutState.loged === true) {
      setShowNotify(true);
    } else {
      dispatchAccount({ type: "setShowOver", payload: true });
      dispatchAccount({ type: "setShowLogin", payload: true });
      dispatchAccount({ type: "setShowSignup", payload: false });
    }
  };

  const handleFileLimit = () => {
    if (imagess.length >= 5) {
      return true;
    }
    return false;
  };

  var htmlEntities = {
    nbsp: " ",
    cent: "¢",
    pound: "£",
    yen: "¥",
    euro: "€",
    copy: "©",
    reg: "®",
    lt: "<",
    gt: ">",
    quot: '"',
    amp: "&",
    apos: "'"
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    setLoading(true);

    // if (
    //   window.config["site-url"] === "https://www.ishtari.com" ||
    //   localStorage.getItem("site-local-name") === "ishtari"
    //   // && localStorage.getItem("currency") !== "USD"
    // ) {
    //   var obj = {
    //     currency: "USD"
    //   };
    //   _axios
    //     .post(buildLink("currency", undefined, window.innerWidth), obj)
    //     .then((response) => {
    //       const data = response.data;
    //       if (data.success === true) {
    //         setLoading(false);
    //         Ref.current = "";
    //         getProduct();
    //         localStorage.setItem("currency", "USD");
    //       }
    //     });
    // } else {
    getProduct();
    // }
  }, [accountstate?.admin]);
  function addGroup() {
    setResult("");

    // alert(1)
    var obj = {};

    obj = {
      name: nameValue,
      description: descriptionValue
    };
    _axios
      .post(buildLink("wishlistAdd", undefined, undefined), obj)
      .then((response) => {
        if (response.data.success) {
          setShowModel(false);
        }
        setResult(response.data);
      });

    // setvalue("");
    setName("");
    setDescription("");
  }
  function CustomPrevArrows({ direction, onClick, style, className }) {
    return (
      <div
        style={{ ...style, padding: "2px 5px" }}
        onClick={onClick}
        className="mySwiper"
      >
        <div className="swiper-button-prev"></div>
      </div>
    );
  }

  function CustomNextArrows({ direction, onClick, style, className }) {
    return (
      <div
        style={{ ...style, padding: "2px 5px" }}
        onClick={onClick}
        className="mySwiper"
      >
        <div className="swiper-button-next"></div>
      </div>
    );
  }

  const productSetting = {
    speed: 200,
    slidesToShow: 7,
    slidesToScroll: 7,
    infinite: false,
    prevArrow: <CustomPrevArrows direction={"l"} />,
    nextArrow: <CustomNextArrows direction={"r"} />
  };

  const moreSettings = {
    speed: 200,
    slidesToShow: 6,
    slidesToScroll: 5,
    infinite: false,
    prevArrow: <CustomPrevArrows direction={"l"} />,
    nextArrow: <CustomNextArrows direction={"r"} />
  };

  const productMobile = {
    dots: false,
    speed: 200,
    slidesToShow: 2.5,
    swipeToSlide: true,
    infinite: false,
    arrows: false,
    lazyLoad: true
  };

  function downloadDatasheet() {
    const link = document.createElement("a");
    link.href = productData?.datasheet;
    link.setAttribute("download", productData?.sku + ".pdf");
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
  }

  // const [token, setToken] = useState();
  // by Omar
  const color = {
    a: "#FFEBCD	",
    b: "#00BFFF",
    c: "#00BFFF",
    d: "#00BFFF",
    e: "#DC143C",
    f: "#bf1b26",
    g: "#008000",
    h: "#008000",
    i: "#008000",
    j: "#008000",
    k: "#008000",
    l: "#008000",
    m: "#008000",
    n: "#008000",
    o: "#F08080	",
    p: "#bf1b26",
    q: "#bf1b26",
    r: "#DC143C",
    t: "#FFD700",
    u: "#bf1b26",
    v: "#bf1b26",
    w: "#bf1b26",
    x: "#bf1b26",
    y: "#00BFFF",
    z: "#00BFFF",
    s: "#DC143C"
  };

  const textColor = {
    a: "#FFEBCD	",
    b: "#00BFFF",
    c: "#00BFFF",
    d: "#00BFFF",
    e: "#DC143C",
    f: "#bf1b26",
    g: "#008000",
    h: "#008000",
    i: "#008000",
    j: "#008000",
    k: "#008000",
    l: "#008000",
    m: "#008000",
    n: "#008000",
    o: "#F08080	",
    p: "#bf1b26",
    q: "#bf1b26",
    r: "#DC143C",
    t: "#FFD700",
    u: "#bf1b26",
    v: "#bf1b26",
    w: "#bf1b26",
    x: "#bf1b26",
    y: "#00BFFF",
    z: "#00BFFF",
    s: "#00BFFF"
  };

  useEffect(() => {
    if (showGroup === true) {
      _axios
        .get(buildLink("wishlist_group", undefined, undefined))
        .then((response) => {
          setGroupsWishlist(response.data.data);
        });
    }
  }, [showGroup]);

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth",
    // });
    // getProduct();
    // var link = buildLink("product", undefined, window.innerWidth) + product_id;
    // _axios.get(link).then((response) => {
    //   const data = response.data;
    //   if (data.product.error) {
    //     setNoProductData(true);
    //   } else {
    //     setProductData(data.product);

    //     //banner_event
    //     if (data.product.special_end != null) {
    //       _axios
    //         .get(
    //           buildLink("banner_event" , undefined, window.innerWidth) + "&special_end="+
    //             data.product.special_end
    //         )
    //         .then((response) => {
    //           setHasBannerEvent(response.data);
    //           console.log(response.data);
    //         });
    //     }
    //     data.product.images.unshift({
    //       popup: data.product.popup,
    //       thumb: data.product.thumb
    //     });
    //     setImages(data.product.images);
    //     setHasOption(data.product.options.length > 0);
    //     data.product.options.length > 0 &&
    //       setOptionParent(data.product.options[0]["product_option_id"]);
    //     // ---> Facebook PIXEL <---
    //     // ReactPixel.init(pixelID, {}, { debug: true, autoConfig: false });
    //     // ReactPixel.pageView();
    //     // ReactPixel.fbq("track", "PageView");

    //     // ReactPixel.track("ViewContent", {
    //     //   content_type: "product",
    //     //   content_ids: [product_id],
    //     //   content_name: data.product.name?.replace(/&amp;/g, "&"),
    //     //   value: data.product.price_net_value,
    //     //   currency: "USD"
    //     // });

    //     if (!accountstate.admin) {
    //      var dataSocial = data.product
    //       const obj = {
    //         content_ids: dataSocial?.social_data?.content_ids,
    //         contents: dataSocial?.social_data?.contents,
    //         email: data?.email,
    //         fb_login_id: data?.fb_login_id,
    //         firstname: data?.firstname,
    //         ip: data?.ip,
    //         lastname: data?.lastname,
    //         name: data?.name,
    //          quantity: data.quantity,
    //         telephone: data?.telephone,
    //         user_agent: data?.user_agent,
    //          value: data?.value,
    //         from: data?.from,
    //       };

    //       _axios.post(buildLink("pixel"), obj).then((response) => {
    //         const data = response.data;
    //         if (data.success === true) {
    //         }
    //       });
    //     }
    //   }
    //   getReview();
    // });

    // ---> Facebook PIXEL <---

    handleWishlist(0);
    return () => {
      setProductData({});
      setImages([]);
      setActiveImage({});
      setHasOption(false);
    };
  }, [product_id]);

  function getProduct() {
    window.scrollTo({
      top: 0
      // behavior: "smooth",
    });

    setLoading(true);
    setLoader(true);
    const q_s = queryString.parse(location.search);
    var page = q_s.page ? q_s.page : 1;
    var link =
      buildLink("product", undefined, window.innerWidth) +
      `${accountstate.admin
        ? product_id +
        `${window.config["site-url"].indexOf("ishtari") > -1
          ? "&clear=true"
          : ""
        }`
        : product_id
      }${location?.state?.fromSearch ? "&from_search=1" : ""
      }&source_id=1&part_one=true`;
    _axios.get(link).then((response) => {
      const data = response.data;
      if (!data?.success) {
        setNoProductData(true);
        setProductNotFoundd(true);
        setLoading(false);
        loadingContext.done();
      } else {
        setProductData(data.data);

        setProductBundle(
          data.data?.product_bundles?.length > 0
            ? data.data?.product_bundles[0]
            : null
        );
        setReviews(data?.data?.product_reviews?.reviews);

        setProductNotFoundd(false);
        setLoading(false);
        loadingContext.done();
        setChecked(data?.data?.groups_wishlist);

        _axios
          .get(
            buildLink("getProductAdditionalData", undefined, undefined) +
            "&product_id=" +
            product_id
          )
          .then((resp) => {
            // alert(resp.data.purshased_count.purshased_count)
            if (resp.data.purshased_count) {
              setPurchased(resp.data.purshased_count.purshased_count)
            }
            setAdditionalData(resp.data.data);
          });
        //banner_event
        if (data?.data.special_end !== null && data?.data.special_end !== 0) {
          // _axios
          //   .get(
          //     buildLink("banner_event", undefined, window.innerWidth) +
          //       "&special_end=" +
          //       data?.data?.special_end
          //   )
          //.then((response) => {
          setHasBannerEvent(data?.data?.bannerevent);
          //  console.log(response.data);
          // });
        }
        data.data.images.unshift({
          popup: data.data.popup,
          thumb: data.data.thumb
        });
        setImages(data.data.images);
        setHasOption(data?.data.options?.length > 0);
        data.data.options.length > 0 &&
          setOptionParent(data.data.options[0]["product_option_id"]);
        // ---> Facebook PIXEL <---
        if (!accountstate.admin) {
          const advancedMatching = {
            em: data?.data?.social_data?.email,
            fn: data?.data?.social_data?.firstname,
            ln: data?.data?.social_data?.lastname,
            external_id: data?.data?.social_data?.external_id,
            country: data?.data?.social_data?.country_code,
            fbp: Cookies.get("_fbp")
          };
          ReactPixel.init(pixelID, advancedMatching, {
            debug: true,
            autoConfig: false
          });
          ReactPixel.pageView();
          ReactPixel.fbq("track", "PageView");

          // ReactPixel.track("ViewContent", {
          //   content_type: "product",
          //   content_ids: [product_id],
          //   content_name: data?.data?.social_data?.name,
          //   value: data?.data?.social_data?.value,
          // //  event_id: data?.data?.social_data?.event_id,
          //   currency: data?.data?.social_data?.currency,
          //   // country: data?.data?.social_data?.country_code,

          // }, {eventID: data?.data?.social_data?.event_id} );

          window.fbq(
            "track",
            "ViewContent",
            {
              content_type: "product",
              content_ids: [product_id],
              content_name: data?.data?.social_data?.name,
              value: data?.data?.social_data?.value,
              currency: data?.data?.social_data?.currency
            },
            { eventID: data?.data?.social_data?.event_id }
          );
        }
        var dataSocial = data.data.social_data;
        dataSocial["fbp"] = Cookies.get("_fbp");
        dataSocial["fbc"] = Cookies.get("_fbc");
        dataSocial["ttp"] = Cookies.get("_ttp");
        dataSocial["link"] = window.location.href;
        dataSocial["view_type"] = "product";
        dataSocial["view_type_id"] = product_id;

        if (
          source_type === "" ||
          source_type === null ||
          typeof source_type === "undefined"
        ) {
          dataSocial["ignore"] = false;
        } else {
          dataSocial["source_type"] = source_type;
          dataSocial["source_type_id"] = source_type_id;
          dataSocial["banner_image_id"] = banner_image_id
            ? banner_image_id
            : "";
        }

        _axios
          .post(buildLink("pixel", undefined, window.innerWidth), dataSocial)
          .then((response) => {
            const data = response.data;
            if (data.success === true) {
            }
          });
      }
    });
  }
  // function getProductPart2() {
  //   alert(1);
  // }
  // document.addEventListener(
  //   "scroll",
  //   (event) => {
  //     // handle scroll event
  //     if (scroll === false  && loader===false) {
  //       setScroll(true);
  //       setLoader(true);
  //       getProductPart2();
  //     }
  //   }
  //   // { passive: true }
  // );
  //   useEffect(() => {
  // alert(scroll)
  //     if(scroll=== true){
  //       // alert(1)
  //       getProductPart2();

  //     }
  //   }, [scroll]);
  const observer = useRef();

  const lastElementRef = useCallback(
    (node) => {
      if (!loader) {
        return;
      }
      // console.log("omar")
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && loader) {
          setLoader(true);
          getProductPart2();
          setPart2(true);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loader]
  );
  // useEffect(() => {
  //   if (scroll) {
  //     getProductPart2();
  //   }
  // }, [scroll]);
  // function handleScroll() {
  //   // console.log(window.innerHeight);
  //   // console.log(document.documentElement.scrollTop);
  //   // // console.log(document.documentElement.scrollTop)
  //   if (!scroll  &&  document.documentElement.scrollTop > window.innerHeight/2) {
  //     setLoader(true);
  //     setScroll(true);
  //   }
  // }
  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  // useEffect(() => {
  //   if (part && accountstate?.admin) {
  //     getProductPart2();
  //   }
  // }, [accountstate.admin, part]);
  function getProductPart2() {
    //     setLoader(false);
    // alert(loader)
    var link =
      buildLink("product", undefined, window.innerWidth) +
      `${accountstate.admin
        ? product_id +
        `${window.config["site-url"]?.indexOf("ishtari") > -1 ||
          (localStorage.getItem("site-local-name") &&
            localStorage.getItem("site-local-name")?.includes("ishtari"))
          ? "&clear=true"
          : ""
        }`
        : product_id
      }&source_id=1&part_two=true`;
    _axios.get(link).then((response) => {
      const data = response.data;
      if (data?.success) {
        setReviews(data?.data?.product_reviews?.reviews);

        setProductData2(data.data);
        setLoader(false);
      }
    });
  }
  function pageSetter(page) {
    const new_page = parseInt(page["selected"]) + 1;
    setPageValue(new_page);
    getReview(new_page);
  }

  function setOption(option) {
    const option_id = option["product_option_value_id"];

    var count = 0;
    var i = 0;
    while (i < images?.length) {
      if (images[i]?.product_option_value_id > 0) {
        count++;
      }

      i++;
    }

    if (
      images[1]?.product_option_value_id > 0 ||
      productData?.options[0].option_value?.length === count
    ) {
      for (const key in images) {
        if (Object.hasOwnProperty.call(images, key)) {
          const element = images[key];
          if (element["product_option_value_id"] === option_id) {
            setActiveOption(option);
            setImageActiveOption(option);
            setActiveImage({
              popup: element["popup"],
              thumb: element["thumb"]
            });
          }
        }
      }
    }
    setActiveOption(option);

    // setImageActiveOption(option);
  }

  // function setOption(option) {
  //   const option_id = option["product_option_value_id"];
  //   for (const key in images) {
  //     if (Object.hasOwnProperty.call(images, key)) {
  //       const element = images[key];
  //       if (element["product_option_value_id"] === option_id) {
  //         setActiveOption(option);
  //         setActiveImage({ popup: element["popup"], thumb: element["thumb"] });
  //       }
  //     }
  //   }
  //   setActiveOption(option);
  // }

  function handleHoveredSeries(key, name) {
    const seriesOp_name = document.getElementById(key);
    setViewSeriesVal(name);
    seriesOp_name.textContent = name;
  }
  function handleLeavedSeries(key) {
    const seriesOp_name = document.getElementById(key);
    setViewSeriesVal();
    seriesOp_name.textContent = "";
  }

  function unescapeHTML(str) {
    if (!str) {
      return;
    }
    return str.replace(/\&([^;]+);/g, function (entity, entityCode) {
      var match;

      if (entityCode in htmlEntities) {
        return htmlEntities[entityCode];
        /*eslint no-cond-assign: 0*/
      } else if ((match = entityCode.match(/^#x([\da-fA-F]+)$/))) {
        return String.fromCharCode(parseInt(match[1], 16));
        /*eslint no-cond-assign: 0*/
      } else if ((match = entityCode.match(/^#(\d+)$/))) {
        return String.fromCharCode(~~match[1]);
      } else {
        return entity;
      }
    });
  }

  function handleReturnPolicy() {
    _axios
      .get(
        buildLink("information", undefined, window.innerWidth) +
        "&information_id=10"
      )
      .then((response) => {
        const data = response.data.data;
        // console.log(data);
        setReturnPolicy(data);
      });
  }

  // useEffect(() => {
  //   handleReturnPolicy();
  // }, []);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */

      if (showShare) {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setTimeout(() => setShowShare(false), 200);
          }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    }, [ref, showShare]);
  }

  function addToCart(bundle) {
    setCountDonwPointer(true);

    setHasAddToCartError(false);
    setAddingToCart(true);
    let obj = {
      product_id,
      quantity
    };
    if (hasOption) {
      let o = {};
      const op = optionParent.toString();
      o[op] = activeOption["product_option_value_id"];
      obj["option"] = o;
    }
    let error = "";
    // console.log(obj);
    _axios
      .post(
        buildLink("cart", undefined, window.innerWidth) + "&source_id=1",
        bundle === undefined ? obj : bundle
      )
      .then((response) => {
        const data = response.data;
        if (data.success !== true) {
          // There is an error
          setHasAddToCartError(true);
          if (!hasOption) {
            error = data?.errors[0]?.errorMsg;
          } else {
            error = data?.errors[0]?.errorMsg;
          }
          // alert(error)
          setAddToCartError(error);
          setAddingToCart(false);
        } else {
          setCountDown(true);
          setCountDonwPointer(true);
          setTimeout(() => {
            setCountDonwPointer(false);
          }, 1000);
          setTimeout(() => {
            setCountDown(false);
          }, 3000);
          dispatch({
            type: "loading",
            payload: true
          });
          _axios
            .get(buildLink("cart", undefined, window.innerWidth))
            .then((response_data) => {
              dispatch({
                type: "setProducts",
                payload: response_data.data?.data?.products
              });

              dispatch({
                type: "setProductsCount",
                payload: response_data?.data?.data?.total_product_count
              });
              dispatch({
                type: "setTotals",
                payload: response_data.data?.data?.totals
              });
              dispatch({
                type: "loading",
                payload: false
              });
            });
          // if (!accountstate.admin) {
          // ---> Facebook PIXEL <---

          // const advancedMatching = {
          //   em: productData?.social_data?.email,
          //   fn: productData?.social_data?.firstname,
          //   ln: productData?.social_data?.lastname,
          //   external_id: productData?.social_data?.external_id,
          //   country: productData?.social_data?.country_code,
          //   fbp: Cookies.get("_fbp")

          // };
          // ReactPixel.init(pixelID, advancedMatching, {
          //   debug: true,
          //   autoConfig: false
          // });
          // ReactPixel.pageView();
          //   ReactPixel.fbq("track", "PageView");
          if (data) {
            const data = response?.data?.data?.social_data;

            ReactPixel.fbq(
              "track",
              "AddToCart",
              {
                content_type: "product",
                content_ids: data?.content_ids,
                content_name: data?.name,
                value: data?.value,
                content_category: productData?.breadcrumbs?.category[0]?.name,
                currency: data?.currency,
                fbp: Cookies.get("_fbp")
              },
              { eventID: data?.event_id }
            );
          }
          // }

          var dataSocial = response?.data?.data?.social_data;
          dataSocial["link"] = window.location.href;
          dataSocial["fbp"] = Cookies.get("_fbp");
          dataSocial["fbc"] = Cookies.get("_fbc");
          dataSocial["ttp"] = Cookies.get("_ttp");
          // dataSocial["view_type"] = "product";
          // dataSocial["view_type_id"] = product_id;
          // dataSocial["source_type"] = source_type;
          // dataSocial["source_type_id"] = source_type_id;
          // dataSocial["banner_image_id"] = banner_image_id
          //   ? banner_image_id
          //   : "";

          _axios
            .post(buildLink("pixel", undefined, window.innerWidth), dataSocial)
            .then((response) => {
              const data = response.data;
              if (data.success === true) {
              }
            });
          setSuccessAdded(true);

          setTimeout(() => {
            // setCountDown(false)
            setAddingToCart(false);
          }, 3000);
        }
      });
  }

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }

  //page view conversion for google ads
  useEffect(() => {
    if (!accoutState.admin) {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }

      if (window.location.host === "www.ishtari.com") {
        var price = 10;
        if (productData.special_net_value) {
          price = productData.special_net_value;
        } else {
          price = productData.price_net_value;
        }

        gtag("event", "conversion", {
          send_to: "AW-991347483/pc3dCIaww44YEJuG29gD",
          value: price,
          currency: "USD"
        });
      } else if (window.location.host === "www.ishtari.com.gh") {
        var price = 10;
        if (productData.special_net_value) {
          price = productData.special_net_value;
        } else {
          price = productData.price_net_value;
        }

        gtag("event", "conversion", {
          send_to: "AW-10993907106/31DICLmKppEYEKLrpvoo",
          value: price,
          currency: "USD"
        });
      }
    }
  }, []);

  function gtag_report_conversion(obj) {
    if (!accoutState.admin) {
      var price = 10;
      if (productData.special_net_value) {
        price = productData.special_net_value;
      } else {
        price = productData.price_net_value;
      }
      if (window.location.host === "www.ishtari.com") {
        var callback = addToCart(obj);
        gtag("event", "conversion", {
          send_to: "AW-991347483/FGk5CJ3V3owYEJuG29gD",
          value: price,
          currency: "USD",
          event_callback: callback
        });
        return false;
      } else if (window.location.host === "www.ishtari.com.gh") {
        const callback = addToCart(obj);
        gtag("event", "conversion", {
          send_to: "AW-10993907106/6Y9jCLfUipEYEKLrpvoo",
          value: price,
          currency: "USD",
          event_callback: callback
        });
        return false;
      } else {
        addToCart(obj);
      }
    } else {
      addToCart(obj);
    }
  }

  function handleWishlist(counter) {
    if (counter < 1) {
      if (stateW?.pIds?.indexOf(product_id) > -1) {
        setIsWishlist(true);
        counter++;
      } else {
        setIsWishlist(false);
      }
    } else {
    }
  }
  function updateState(id) {
    var checkboxes = document.getElementsByName("wish");
    var checkboxesChecked = [];
    // loop over them all
    for (var i = 0; i < checkboxes.length; i++) {
      // And stick the checked ones onto an array...
      if (checkboxes[i].checked) {
        checkboxesChecked.push(checkboxes[i].value);
      }
      setChecked(checkboxesChecked);
    }
  }

  function addToWishList() {
    // dispatchW({
    //   type: "setProductIds",
    //   payload: [...stateW.pIds, product_id]
    // });
    // if (checked.length > 0) {
    const obj = {
      id: checked,
      product_id: product_id
    };
    _axios
      .post(buildLink("addToWishlist_5", undefined, window.innerWidth), obj)
      .then(() => {
        _axios
          .get(buildLink("wishlistCount", undefined, window.innerWidth))
          .then((response) => {

            if (response.data.success) {
              //   console.log("hii");
              dispatchW({
                type: "setProductsCount",
                payload: response.data.data.total
              });
              dispatchW({
                type: "setProductIds",
                payload: response.data.data.products
              });

              var social_data = productData.social_data;
              social_data["from"] = "addtowishlist";

              _axios
                .post(
                  buildLink("pixel", undefined, window.innerWidth),
                  social_data
                )
                .then((response) => {
                  const data = response.data;
                });
            }
          });
        // setIsWishlist(true)
        setShowGroup(false);

      });
    // } else {
    //   deleteItemFromAllGroup(product_id);
    //   setIsWishlist(false)
    // }
    // if (stateW?.pIds?.indexOf(product_id) > -1) {
    //   _axios
    //     .delete(
    //       buildLink("wishlist", undefined, window.innerWidth) +
    //         "&id=" +
    //         product_id
    //     )
    //     .then(() => {
    //       _axios
    //         .get(buildLink("wishlistCount", undefined, window.innerWidth))
    //         .then((response) => {
    //           if (response.data.success) {
    //             //    console.log("delete");
    //             dispatchW({
    //               type: "setProductsCount",
    //               payload: response.data.data.total
    //             });
    //             dispatchW({
    //               type: "setProductIds",
    //               payload: response.data.data.products
    //             });
    //           }
    //         });
    //     });

    //   _axios
    //     .get(buildLink("wishlist", undefined, window.innerWidth))
    //     .then((response) => {
    //       if (response.data.success) {
    //         // dispatchW({
    //         //   type: "setProducts",
    //         //   payload: response.data.data.products
    //         // });
    //         // dispatchW({
    //         //   type: "setProductsCount",
    //         //   payload: response.data.total_product_count,
    //         // });
    //         dispatchW({
    //           type: "setTotals",
    //           payload: response.data.data.totals
    //         });
    //         const ids = response.data.data.products.map((p) => p.product_id);
    //         dispatchW({
    //           type: "setProductIds",
    //           payload: ids
    //         });
    //         dispatchW({
    //           type: "loading",
    //           payload: false
    //         });
    //         setIsWishlist(false);
    //       } else {
    //         dispatch({
    //           type: "setProductsCount",
    //           payload: 0
    //         });

    //         dispatch({
    //           type: "loading",
    //           payload: false
    //         });
    //       }
    //     });
    // } else {
    //   dispatchW({
    //     type: "setProductIds",
    //     payload: [...stateW.pIds, product_id]
    //   });

    //   const obj = {
    //     id: checked,
    //     product_id: product_id
    //   };
    //   _axios
    //     .post(buildLink("addToWishlist_5", undefined, window.innerWidth), obj)
    //     .then(() => {
    //       _axios
    //         .get(buildLink("wishlistCount", undefined, window.innerWidth))
    //         .then((response) => {
    //           if (response.data.success) {
    //             //   console.log("hii");
    //             dispatchW({
    //               type: "setProductsCount",
    //               payload: response.data.data.total
    //             });
    //             dispatchW({
    //               type: "setProductIds",
    //               payload: response.data.data.products
    //             });
    //           }
    //         });
    //       setShowGroup(false);
    //       
    //     });
    // }
  }
  useEffect(() => {
    handleWishlist(0);
  }, [stateW]);

  function deleteItemFromAllGroup() {
    _axios
      .post(
        buildLink("removeAll", undefined, undefined) +
        "&product_id=" +
        product_id
      )
      .then((response) => {
        if (response.data.success) {
          // setShowGroup(false);
          setIsWishlist(false);

          _axios
            .get(buildLink("wishlistCount", undefined, window.innerWidth))
            .then((response) => {
              if (response.data.success) {
                //   console.log("hii");
                dispatchW({
                  type: "setProductsCount",
                  payload: response.data.data.total
                });
                dispatchW({
                  type: "setProductIds",
                  payload: response.data.data.products
                });
              }
            });
        }
        setShowGroup(false);
      });
  }
  const [imagenotification, setimagenotification] = useState();
  const [hasImageNotificationShown, setHasImageNotificationShown] =
    useState(false);
  function getReview(page) {
    commentRef.current.scrollIntoView({ behavior: "smooth" });

    var obj = { product_id: product_id };
    _axios
      .get(
        buildLink("reviews") +
        "&product_id=" +
        product_id +
        "&page=" +
        page +
        "&limit=5",
        obj
      )
      .then((response) => {
        const data = response.data;
        if (data.success === true) {
          setReview(response?.data?.data.rev_avg);
          setReviews(response?.data?.data?.reviews);
        } else {
          setReviews("");
        }
      });
  }

  function ratingChanged(i) {
    //console.log(i);
    setRatingCustomer("");
    setRatingCustomer(i);
  }

  // function showMore() {
  //   if (show === true) {
  //     setShow(false);
  //   } else {
  //     setShow(true);
  //   }
  // }

  function addReview() {
    // const object = {
    //   product_id: product_id,
    //   rating: ratingCustomer,
    //   text: textRef.current.value,
    //   customer_id: localStorage.getItem("cid"),
    //   images: []
    // };
    if (ratingCustomer > 0) {
      if (ReviewImages.length === 0 && !hasImageNotificationShown) {
        setimagenotification(
          "Upload At Least One Image To Get More Points If There Are Points On The Image"
        );
        setHasImageNotificationShown(true);
        return;
      }
      var formData = new FormData(); // Currently empty

      formData.append("product_id", product_id);
      formData.append("rating", ratingCustomer);
      formData.append("comment", textRef.current.value);
      formData.append("source_id", 1);

      //formData.append("images", []);
      imagess.slice(0, 5).map((image) => {
        formData.append("images[]", image);
      });

      _axios
        .post(buildLink("reviews", undefined, window.innerWidth), formData)
        .then((response) => {
          // const data = response.data;
          // if (data.success) {
            // window.location.reload();

          // window.location.reload();
          window.scrollTo(0, 0);
          // } else {
          //   setErrorSizeMsg(data.error);
          //   setTimeout(() => {
          //     setErrorSizeMsg("");
          //   }, 5000);
          // }
        });
    } else {
      setRequired("Please provide a rating");
    }
  }

  // function filterReview(i) {
  //   let s = i + 1;
  //   // console.log(filter);
  //   var arr = filter;
  //   // console.log(s);
  //   if (!arr.includes(s)) {
  //     arr.push(s);
  //   } else {
  //     //  console.log("exist");
  //     arr.splice(arr.indexOf(s), 1);
  //     //  console.log(arr);
  //   }

  //   setFilter(arr);
  //   // setFilter(i);
  //   getReview();
  //   // console.log(filter)
  //   // if(filter.indexOf(i) !== -1){
  //   //   console.log("exist")
  //   // }
  //   // console.log(i);
  // }

  function addBundle(bundle) {
    // console.log(bundle);
    if (
      bundle.products.filter((p) => p.product_options?.length > 0).length == 0
    ) {
      bundle.products.map((p) => {
        const obj = {
          product_id: p.product_id,
          quantity: Number(p.required_quantity)
        };
        //addToCart(obj);
        gtag_report_conversion(obj);
      });
    } else {
      setShowOptionModal({ show: true, bundle: bundle });
    }
  }

  return (
    <div className="relative">
      {/* If there is no product data */}
      {/* {noProductData && <div className="">Product not found</div>} */}
      {noProductData && (
        <div className="flex items-center justify-center mt-20 pb-5 flex-col">
          <img src={notFound} className=" w-2/12" alt="Not Found" />
          <h2 className="text-2xl mt-4">Sorry, there is nothing here!</h2>
          <Link
            to={`${path}/`}
            className="bg-dblue text-white px-10 py-3 rounded mt-4"
          >
            START SHOPPING
          </Link>
          <button
            onClick={() => history.goBack()}
            className="bg-dbase text-white px-10 py-3 rounded mt-4"
          >
            Go back
          </button>
        </div>
      )}

      {productData.name && !loading ? (
        productData.status === "0" && !accountstate.admin ? (
          <div className="flex items-center justify-center mt-20 pb-5 flex-col">
            <img src={notFound} className=" w-2/12" alt="Not Found" />
            <h2 className="text-2xl mt-4">Sorry, there is nothing here!</h2>
            <Link
              to={`${path}/`}
              className="bg-dblue text-white px-10 py-3 rounded mt-4"
            >
              START SHOPPING
            </Link>
            <button
              onClick={() => history.goBack()}
              className="bg-dbase text-white px-10 py-3 rounded mt-4"
            >
              Go back
            </button>
          </div>
        ) : (
          <div>
            <SeoHandler
              data={{
                title: productData.name,
                description: productData.description,
                imageURL: productData.popup
              }}
            />
            {showOptionModal.show && (
              <ProductOptionModal
                setShowOptionModal={setShowOptionModal}
                bundle={showOptionModal.bundle}
                addCart={addToCart}
              />
            )}
            <div>
              {successAdded && (
                <div
                  className=" block md:hidden fixed top-0 left-0 right-0 min-h-screen w-full bg-dblack bg-opacity-30 z-40"
                  onClick={() => setSuccessAdded(false)}
                >
                  <div className="bg-white py-4 px-2 text-sm">
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(productData.name)
                      }}
                    />
                    <p className="mt-2 text-dgreen">
                      Successfully added to cart
                    </p>
                    <div className="flex items-center justify-between mt-4 space-x-4">
                      <Link
                        to={`${path}/checkout`}
                        className="rounded text-center bg-dblue text-white py-2 w-3/6 border border-dblue font-semibold"
                      >
                        CHECKOUT
                      </Link>
                      <button className="rounded py-2 w-3/6 border border-dblue text-dblue ">
                        CONTINUE SHOPPING
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {successAdded && (
                <div
                  className="fixed w-screen h-full min-h-screen top-0 left-0  bg-opacity-30 bg-dblack z-30"
                  onClick={() => setSuccessAdded(false)}
                ></div>
              )}

              <div
                className={`${width < 650 && "hidden"}`}
                onClick={() => setSuccessAdded(false)}
              >
                <div
                  className={`${width < 650 && "hidden"
                    } top-0  bg-white right-0 w-3/12 px-4 min-h-screen transform   fixed h-full z-40  ease-in-out duration-300 ${successAdded ? "translate-x-0 " : "translate-x-full"
                    }`}
                >
                  <div>
                    <div className=" border-b border-dinputBorder py-2">
                      <div className="flex ">
                        <img
                          src={productData.thumb}
                          alt={productData.name}
                          className=" w-4/12 rounded"
                        />
                        <div className="mt-2 pl-4 py-2">
                          <p
                            className="text-xs font-semibold "
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(productData.name)
                            }}
                          />
                          {hasBannerEvent?.name !== undefined &&
                            hasBannerEvent?.name !== "" ? (
                            <p
                              className="text-xs font-semibold "
                              dangerouslySetInnerHTML={{
                                __html:
                                  hasBannerEvent?.name +
                                  " - " +
                                  productData.name?.toUpperCase()
                              }}
                            />
                          ) : (
                            <p
                              className="text-xs font-semibold "
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  productData.name.toUpperCase()
                                )
                              }}
                            />
                          )}
                          <p className="text-xs font-semibold mt-2 text-dgreen">
                            Added To Cart
                          </p>
                        </div>
                      </div>

                      <div className="flex justify-between items-center  mt-2 mb-2">
                        <Link
                          to={`${path}/cart/`}
                          className="text-center text-xs bg-dblue border border-dblue rounded text-white py-2 px-2 flex-grow mr-2 hover:bg-dbluedark"
                        >
                          CHECKOUT
                        </Link>
                        <button
                          onClick={() => setSuccessAdded(false)}
                          className=" border text-xs border-dblue rounded text-dblue py-2 flex-grow ml-2 hover:bg-dblue hover:text-white"
                        >
                          CONTINUE SHOPPING
                        </button>
                      </div>
                    </div>

                    <h2 className="my-4 font-semibold text-dblack text-xl">
                      Basket
                    </h2>
                    {state.products?.map((product) => (
                      <div className="flex border-b border-dinputBorder py-2">
                        <img
                          src={product.thumb}
                          alt={product.name}
                          className=" w-3/12 rounded"
                        />
                        <div className="flex flex-col items-start justify-between pl-4 py-2">
                          <p
                            className=" text-sm font-light"
                            dangerouslySetInnerHTML={{
                              __html:
                                product.name.substring(0, 75) +
                                (product.name.length > 75 && "...")
                            }}
                          />
                          <p className="font-semibold">
                            {product.total + " "} ( {product.quantity} item
                            {product.quantity > 1 && <span>s</span>} )
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {/* )} */}
            {/* First row */}
            <div
              className={`w-screen ${width > 650 && "overflow-hidden"} ${accountstate.admin && productData?.status === "0"
                ? "bg-dPink"
                : "bg-white"
                }`}
            >
              <div className="container text-dblack">
                {productData?.breadcrumbs.category && (
                  <div className="pl-2 py-2 md:pt-2  md:pb-5 flex items-center">
                    <Link
                      to="/"
                      className="hidden md:block text-dblack font-light truncate text-d11 md:text-tiny mr-2 hover:text-dblue"
                      dangerouslySetInnerHTML={{
                        __html: "Home"
                      }}
                    />
                    {width > 650 && (
                      <i className="icon icon-angle-right text-d11 md:text-tiny text-dgrey1" />
                    )}

                    {/* <p className="text-dblack font-light text-d11 md:text-tiny mx-2">
                    <span
                      className="truncate... text-d11 md:text-tiny"
                      dangerouslySetInnerHTML={{
                        __html: productData.product_categories[0]?.name
                      }}
                    />
                  </p> */}
                    <Link
                      to={`/category/${productData?.breadcrumbs?.category[0]?.category_id}`}
                      className="hidden md:block text-dblack font-light truncate text-d11 md:text-tiny mx-2 hover:text-dblue"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          productData?.breadcrumbs?.category[0]?.name
                        )
                      }}
                    />
                    {/* <i className="hidden md:block icon icon-angle-right text-d11 md:text-tiny text-dgrey1" />
                  <p
                    className="hidden md:block text-dblack font-light truncate text-d11 md:text-tiny mx-2"
                    dangerouslySetInnerHTML={{
                      __html: productData.name
                    }}
                  /> */}
                  </div>
                )}
                <div className="flex-row md:flex ">
                  {/* Photo */}
                  <div className="product-zoom w-full  md:w-5/12 relative">
                    {/* 52887 */}
                    {productData?.images?.length > 0 && (
                      <ProductZoom
                        activeOption={activeImageOption.product_option_value_id}
                        images={productData.images}
                        hovered={hovered}
                        productData={productData}
                        additionalData={additionalData}
                      />
                    )}
                    {/* <div onClick={() => setShowShare(true)}>
                      <Share
                        image={productData.popup}
                        share={showShare}
                        wrapperRef={wrapperRef}
                        name={productData.name}
                      />
                    </div> */}
                  </div>

                  {/* info */}
                  <div
                    className=" 
                    px-3
                    inline-block
                    w-full
                    2xl:w-4/12
                    xl:w-4/12
                    lg:w-4/12
                    md:w-4/12"
                  >
                    {/* BRAND NAME */}
                    <Link
                      to={`/${productData?.manufacturer +
                        "/m=" +
                        productData.manufacturer_id
                        }`}
                      className="text-dgrey1 hover:text-dblue"
                    >
                      {productData?.manufacturer_image ? (
                        <img
                          src={productData.manufacturer_image}
                          alt={productData.manufacturer_id}
                          className="w-24"
                        />
                      ) : (
                        productData?.manufacturer?.toUpperCase()
                      )}
                    </Link>
                    {hasBannerEvent?.name ? (
                      <h1
                        dangerouslySetInnerHTML={{
                          __html:
                            hasBannerEvent?.name?.toUpperCase() +
                            " - " +
                            productData?.name?.toUpperCase()
                        }}
                        className={`font-semibold text-dblack text-d15 md:text-d22 ${width > 650 ? "hp text-d22" : "hpm text-d15"
                          }`}
                      // style={{ lineHeight: "31.36px"}}
                      />
                    ) : (
                      <h1
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            productData?.name?.toUpperCase()
                          )
                        }}
                        className={`font-semibold text-dblack text-d15 md:text-d22 ${width > 650 ? "hp text-d22" : "hpm text-d15"
                          }`}
                      // style={{ lineHeight: "31.36px"}}
                      />
                    )}
                    {productData?.seller_id > 0 &&
                      productData.seller !== "" && (
                        <Link
                          to={
                            `${path}/${productData.seller}/s=` +
                            productData.seller_id
                          }
                          className=" flex sm:hidden items-center  cursor-pointer mr-5 md:mr-0 hover:opacity-80 py-2 md:py-6"
                        >
                          <i className="icon icon-shop text-dbase text-2xl mr-4" />
                          <span className="text-dblack text-sm">Sold by</span>

                          <h1 className="text-dblue underline font-semibold ml-2 text-sm">
                            {productData.seller}
                          </h1>
                        </Link>
                      )}
                    {/* Model number */}
                    {/* <div className="flex font-lght">
                      <p className="text-dgrey1 text-d15 mb-1 md:mb-3 font-light pr-3">
                        Model Number : {productData.sku}
                      </p>
                      {productData?.rating > 0 && (
                        <div className="flex" onClick={handleClick}>
                          {" "}
                          |{" "}
                          <div
                            className="flex rounded-full px-1 space-x-0.5 h-5 ml-3 mt-0.5   cursor-pointer"
                            style={{ backgroundColor: "rgb(130, 174, 4" }}
                          >
                            <div className="text-d15 font-bold text-white">
                              {productData?.rating || "0.0"}
                            </div>
                            <StarRatings
                              containerClassName="pt-1 text-white text-bold"
                              emptyColor="#FFFFFF"
                              count={1}
                              size="12"
                              isReadOnly="true"
                            />{" "}
                          </div>
                          <Link className=" flex text-dgrey1 text-d15 mb-1 md:mb-3 font-light  ml-2 underline_effect cursor-pointer">
                            {productData?.nb_of_reviews}{" "}
                            {productData?.nb_of_reviews > 1
                              ? "Ratings"
                              : "Rating"}
                          </Link>
                        </div>
                      )}{" "}
                    </div> */}
                    <div className="product-model-rating mb-3 text-d14 text-dgreyProduct flex flex-wrap items-center">
                      <div className="modelNumber mr-1.5">
                        Model Number: {productData.sku}
                      </div>
                      {productData?.rating > 0 && (
                        <div className="divider h-4 w-0.5 bg-dplaceHolder mx-1.5"></div>
                      )}
                      <div className="product-rating">
                        {productData?.rating > 0 && (
                          <div
                            className="flex items-center"
                            onClick={handleClick}
                          >
                            <div
                              className="flex justify-center items-center flex-row  rounded-full h-4 space-x-0.5 p-1 -mt-1 cursor-pointer"
                              style={{
                                backgroundColor:
                                  productData?.rating >= 4.5
                                    ? "rgb(0,158,0)"
                                    : productData?.rating < 4.5 &&
                                      productData?.rating >= 4
                                      ? "rgb(110, 159, 0)"
                                      : productData?.rating < 4 &&
                                        productData?.rating >= 3.5
                                        ? "rgb(243, 153, 22)"
                                        : "rgb(246,90,31)",
                              }}
                            >
                              <div className=" font-bold text-white text-d14 ">
                                {productData?.rating || "0.0"}
                              </div>

                              <AiFillStar className="text-white text-d12" />
                              {/* <StarRatingss
                                containerClassName=" text-white text-bold"
                                starEmptyColor="#FFFFFF"
                                numberOfStars={1}
                                starDimension="13px"
                                isReadOnly="true"
                              />{" "} */}
                            </div>
                            <p className=" flex text-dgrey1 text-d15  md:mb-3 font-light  ml-2 underline_effect cursor-pointer">
                              {productData.nb_of_reviews} Rating
                              {productData.nb_of_reviews > 1 ? "s" : ""}
                            </p>
                          </div>
                        )}{" "}
                      </div>
                    </div>

                    {/* Prices */}
                    <div className="mb-3">
                      <div
                        className={
                          width < 650
                            ? "hidden"
                            : productData.special !== "0"
                              ? "mb-3 flex items-center"
                              : "hidden"
                        }
                      >
                        <span
                          className={
                            productData.special !== "0"
                              ? "w-14 text-dblack font-medium text-d11 pr-3 "
                              : "hidden"
                          }
                        >
                          Was:
                        </span>
                        <span
                          className={
                            productData.special !== "0"
                              ? "line-through md:text-sm pr-3 text-dgrey1 font-light"
                              : "hidden"
                          }
                        >
                          {productData.price}
                        </span>
                      </div>
                      <div className="mb-3 flex items-center">
                        {productData.special !== "0" ? (
                          <span className="hidden md:block w-14 text-dblack font-medium md:text-d13 pr-3 ">
                            Now:
                          </span>
                        ) : (
                          <span className="hidden md:block w-14 text-dblack font-medium md:text-d13 pr-3 ">
                            Price:
                          </span>
                        )}
                        <span className="font-bold text-dblack text-md">
                          <span className={`${width < 650 && "text-d20"}`}>
                            {productData.special !== "0"
                              ? productData.special
                              : productData.price}
                          </span>
                          {((productData.special_net_value &&
                            productData.special_net_value <
                            curr?.data?.allowed_amount_to_pay_in_lbp) ||
                            productData.price_net_value <
                            curr?.data?.allowed_amount_to_pay_in_lbp) &&
                            (window.config["site-url"] ===
                              "https://www.ishtari.com" ||
                              localStorage.getItem("site-local-name") ===
                              "ishtari") && (
                              <span className={`${width < 650 && "text-d20"}`}>
                                {" "}
                                {productData.lbp_special
                                  ? " - " + productData.lbp_special
                                  : " - " + productData.lbp_price}
                              </span>
                            )}
                        </span>
                        <span
                          className={
                            width < 650 && productData.special !== "0"
                              ? "ml-5 line-through text-dgrey1 text-sm font-light"
                              : "hidden"
                          }
                        >
                          {productData.price}
                        </span>
                        {/* <span
                        className={
                          (width < 650 && productData?.special?.length > 0)
                            ? "bg-dgreenop ml-12 text-dgreen font-semibold px-2 py-1 text-d11"
                            : "hidden"
                        }
                      >
                        {productData.saving + "% OFF Test"}
                      </span> */}
                      </div>
                      <div
                        className={
                          width < 650
                            ? "none"
                            : productData.special !== "0"
                              ? "mb-3 flex items-center"
                              : "hidden"
                        }
                      >
                        <span
                          className={
                            productData.special !== "0"
                              ? "w-14 text-dblack font-medium text-d13 pr-3"
                              : "invisible"
                          }
                        >
                          Saving:
                        </span>
                        <span
                          className={
                            productData.special !== "0"
                              ? "bg-dgreenop text-dgreen font-semibold px-2 text-xs"
                              : "invisible"
                          }
                        >
                          {productData.saving + "% OFF"}
                        </span>
                      </div>
                    </div>
                    {/* Add to basket */}
                    {productData["quantity"] <= 5 &&
                      productData["quantity"] > 0 && (
                        <div className="flex text-d15 mt-4">
                          <div className="w-16 font-semibold">Quantity</div>
                          <div className="text-dbase ml-2">
                            Low stock: only {productData["quantity"]} left
                          </div>
                        </div>
                      )}
                    <div
                      className={`flex mb-4 ${productData["quantity"] <= 5 ? "mt-1" : "mt-4"
                        }`}
                    >
                      <input
                            onChange={(e) => setQuantity(e.target.value)}
                            type="number"
                            value={quantity}
                            className={`${
                              productData["quantity"] === "0" ? "hidden" : ""
                            }border w-16 h-12 rounded text-dblack text-center border-dgrey1 text-xl `}
                          />
                      <div className=" w-full flex flex-col justify-center gap-1">
                        <button
                          className={` text-white flex-grow h-12 relative rounded  ${productData["quantity"] === "0"
                            ? "bg-dbase mr-1 ml-1"
                            : "bg-dblue mx-1 hover:bg-dbluedark"
                            } ${stateAccount.loged ? "w-10/12" : "w-full"
                            }  flex items-center justify-center rounded-md text-white `}
                          onClick={() => {
                            productData["quantity"] === "0"
                              ? console.log("yep no")
                              : gtag_report_conversion();
                          }}
                        >
                          <span>
                            {productData["quantity"] === "0" ? (
                              "Out Of Stock"
                            ) : (
                              <div>
                                <p
                                  className={`absolute  transition duration-100 ease-in left-5 md:left-14 top-3 text-white ${successAdded && countDown && !countDownPointer
                                    ? "translate-x-0 "
                                    : "translate-x-full invisible"
                                    } `}
                                >
                                  <span className="bg-white  px-2 rounded-full text-dblue">
                                    1
                                  </span>{" "}
                                  item Added to the Cart
                                </p>
                                {countDownPointer === true &&
                                  hasAddToCartError === false ? (
                                  <div className="top-5 lds-ellipsis">
                                    <div />
                                    <div />
                                    <div />
                                    <div />
                                  </div>
                                ) : (
                                  !addingToCart && (
                                    <span className="">Add To Basket </span>
                                  )
                                )}
                              </div>
                            )}
                          </span>{" "}
                        </button>
                        {/* <div className="w-full flex items-center justify-center pr-semibold mt-1 mobile:text-d18 text-dbluedark">
                            {purchased &&
                              purchased > 100 &&
                              purchased + " Customers Purchased"}
                          </div> */}
                        <div className="w-full flex items-center justify-center pr-semibold mt-1 mobile:text-d18 text-dbluedark">
                          {purchased &&
                            purchased > 50 &&
                            purchased + " Customers Purchased"}
                        </div>
                      </div>
                      {accountstate.loged && (
                        <button
                          className={`h-12 w-16 bg-dgrey rounded-full ml-4`}
                          onClick={() => {
                            stateW.pIds.filter((i) => i === product_id).length >
                              1
                              ? setShowGroup(true)
                              : stateW.pIds.indexOf(product_id) > -1
                                ? deleteItemFromAllGroup()
                                : setShowGroup(true);
                          }}
                        >
                          <i
                            className={
                              isWishlist
                                ? "icon icon-heart text-dbase text-2xl"
                                : "icon icon-heart text-dgrey1 text-2xl"
                            }
                          />
                        </button>
                      )}
                    </div>
                    <div>
                      {productData.quantity === "0" && (
                        <div>
                          {width > 650 ? (
                            <div
                            onClick={() => checkIfLogged()}
                            className=" bg-dblue   text-white rounded-md flex cursor-pointer items-center px-3 py-2  md:px-3 justify-center w-3/4 "
                          >
                            <p className="text-sm md:text-md font-semibold">
                              Notify Me When Available
                            </p>
                            <p>
                              {" "}
                              <HiOutlineMail className=" text-white w-5 h-5 ml-3" />
                            </p>
                          </div>
                          ) : (
                            <div
                              onClick={() => checkIfLogged()}
                              className="fixed z-10 bottom-1 bg-dblue ml-3  text-white rounded-full  cursor-pointer  px-3  md:px-3  w-3/4 "
                            >
                              <div className="my-1 flex justify-around items-center">
                                <p className="text-sm md:text-md font-semibold">
                                  Notify Me When Available
                                </p>
                                <p>
                                  {" "}
                                  <i className="icon icon-mail text-white text-2xl  " />
                                </p>
                              </div>
                            </div>
                          )}

                          <NotifyMe
                            showNotify={showNotify}
                            pname={productData.name}
                            pid={product_id}
                            setShowNotify={setShowNotify}
                          />
                        </div>
                      )}
                    </div>

                    <div className="flex  flex-col gap-1">
                      {productData?.series && productData?.series?.length > 0 &&
                        productData.series.length > 0 &&
                        productData["series_options"].length < 1 && (
                          <div className="w-full border-t-4 border-dinputBorder ">
                            <div className="mobile:px-12 ">
                              <div className=" overflow-x-auto ">
                                <div className="flex  flex-col gap-2">
                                  {productData.series.map((serie, index) => (
                                    serie.is_primary && serie?.products?.length > 0 &&
                                    <div className=" h-fit w-full p-1  " >
                                      <p className=" text-dblack before:w-full mt-2 decoration-dblack  "> {serie.group_type ? serie.group_type : index === 0 ? "In the same series" : ""} : {" "} </p>
                                      <div className="max-md:grid grid-cols-3   md:flex text-left flex-wrap gap-2  justify-start mb-2">
                                        {serie.products && serie.products.map((product) => (
                                          product.disabled && !stateAccount.admin ? <></> :
                                            <div
                                              onClick={(e) => {
                                                if (product.out_of_stock && !stateAccount.admin) {
                                                  e.preventDefault()
                                                } else {
                                                  history({
                                                    pathname: `/${slugify(product.product_name)}/p=` + product.product_id,
                                                  })
                                                }
                                              }
                                              }
                                              key={product.product_id}
                                              className={`flex  justify-center items-center py-1 px-1 max-w-[110px] w-full transition-all  border-2 hover:bg-dgrey  hover:shadow cursor-pointer  rounded-md ${product.product_id === product_id
                                                ? " border-dblue"
                                                : product.disabled
                                                  ? "border-dbase"
                                                  : "border-dgrey"
                                                }`}
                                            >
                                              <div className=" relative overflow-hidden flex gap-1 flex-col justify-between text-center ">
                                                <div className=" relative overflow-hidden flex gap-1 flex-col justify-between text-center">
                                                  <div className=" text-xs text-dblack relative">
                                                    {product.type}
                                                    {product.out_of_stock && (
                                                      <div className="absolute mt-2.5 mr-4 inset-2 flex justify-center items-center ">
                                                        <div className="flex flex-col  items-center">
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="h-12 text-dbase text-opacity-50"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                          >
                                                            <path
                                                              fillRule="evenodd"
                                                              d="M13.414 10l3.293 3.293a1 1 0 01-1.414 1.414L12 11.414l-3.293 3.293a1 1 0 01-1.414-1.414L10.586 10 7.293 6.707a1 1 0 111.414-1.414L12 8.586l3.293-3.293a1 1 0 111.414 1.414L13.414 10z"
                                                              clipRule="evenodd"
                                                            />
                                                          </svg>
                                                          <div className="text-dblack">
                                                            {product.type}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                        ))}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>

                    {hasAddToCartError && (
                      <div className=" bg-dbase text-white text-center h-11 rounded bg-opacity-80 capitalize relative flex items-center justify-center ">
                        <span className="px-8">{AddToCartError}</span>
                        <span
                          onClick={() => {
                            setHasAddToCartError(false);
                            setCountDonwPointer(false);
                          }}
                          className=" rounded absolute top-0 right-0 cursor-pointer w-11 h-11 flex items-center justify-center hover:bg-white hover:text-dbase border border-dbase border-l-0"
                        >
                          <span className="text-xl">X</span>
                        </span>
                      </div>
                    )}
                    {/* Options */}
                    {/* {productData?.options && productData.options?.length > 0 && ( */}
                    <div className="my-1 md:my-4">
                      <div className="flex justify-between">
                        {productData?.options &&
                          productData.options?.length > 0 && (
                            <div className="flex justify-between items-center">
                              <h3
                                className="text-sm"
                                style={{ color: "rgb(126, 133, 155)" }}
                              >
                                {`${productData["options"]["0"]["name"]} ${viewColor ? ":" : ""
                                  }`}
                              </h3>
                              <span className="flex ml-1 font-semibold text-sm w-28">
                                {viewColor}
                              </span>
                            </div>
                          )}
                        {productData?.datasheet && (
                          <div
                            className={` text-right cursor-pointer text-sm ${productData.options?.length > 0
                              ? "w-1/2"
                              : "w-full"
                              } `}
                            onClick={() => downloadDatasheet()}
                          >
                            <div className="tooltipp">
                              <span className="tooltiptextt text-xs">
                                preview to download
                              </span>
                              <u className="underline_effect">Datasheet</u>
                            </div>
                          </div>
                        )}
                        {productData?.options &&
                          productData.options?.length > 0 &&
                          productData["options"]["0"]["size_guide"] && (
                            <h2
                              className="w-1/2 text-right "
                              onClick={() => setShowNewSizeGuide(true)}
                            >
                              {" "}
                              <u className="underline_effect cursor-pointer text-sm">
                                Size Guide
                              </u>
                            </h2>
                          )}
                      </div>{" "}
                      {productData?.options &&
                        productData.options?.length > 0 && (
                          <div className="flex flex-wrap ">
                            {productData["options"]["0"]["option_value"].map(
                              (option) => (
                                <div className="mr-3">
                                  {/* <p className="text-xs text-center">
                                {option["name"]}
                              </p> */}
                                  <div
                                    key={option.image}
                                    className={`p-1 border mr-2 my-2 cursor-pointer hover:shadow w-10 md:w-12 md:h-12 rounded relative ${option.product_option_value_id ===
                                      activeOption.product_option_value_id
                                      ? "border-dblue"
                                      : " border-dgrey"
                                      }`}
                                    onClick={() => {
                                      setOption(option);
                                      setColorSelected(option["name"]);
                                    }}
                                    onMouseOver={() => {
                                      setViewColor(option["name"]);
                                    }}
                                    onMouseLeave={() => {
                                      setViewColor(colorSelected);
                                    }}
                                  >
                                    {option.quantity === "0" && (
                                      <div className=" bg-dgrey bg-opacity-50 w-full h-full absolute top-0 left-0 flex items-center justify-center cursor-not-allowed">
                                        <div className=" text-dblack text-4xl font-bold">
                                          X
                                        </div>
                                      </div>
                                    )}
                                    <LazyLoadImage
                                      src={option["image"]}
                                      key={option.image}
                                      alt={"Option"}
                                      placeholdersrc="https://www.sari3.com/ishtaridemo/product_placeholder.png"
                                    />
                                  </div>
                                  {accountstate.admin && (
                                    <div className="w-full text-center font-bold">
                                      {option?.quantity}
                                    </div>
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        )}
                      {productData?.options &&
                        productData.options?.length > 0 &&
                        newSizeGuide && (
                          <div
                            className="fixed w-screen h-full min-h-screen top-0 left-0  bg-opacity-30 bg-dblack z-30"
                            onClick={() => setShowNewSizeGuide(false)}
                          ></div>
                        )}
                      <div>
                        {productData?.options &&
                          productData.options?.length > 0 && (
                            <div
                              className=""
                            // onClick={() => setShowNewSizeGuide(false)}
                            >
                              <div
                                className={` top-0  bg-white right-0 lg:w-1/3 w-10/12 min-h-screen transform  fixed h-full z-40 
                               ease-in-out duration-300 ${newSizeGuide
                                    ? "translate-x-0 "
                                    : "translate-x-full"
                                  }`}
                              >
                                <div>
                                  <div
                                    className={`border-b-4 border-dborderColor ${width < 650 ? "mx-4" : "mx-6"
                                      }  `}
                                  >
                                    <h4 className="mt-2 font-light text-dgrey1 text-d16">
                                      Size Guide
                                    </h4>
                                  </div>
                                  <div
                                    className={`flex flex-wrap justify-center items-center pr-1  `}
                                  >
                                    <div>
                                      <LazyLoadImage
                                        src={
                                          productData["options"]["0"][
                                          "size_guide"
                                          ]
                                        }
                                        className={`${width < 650 ? "pb-24" : "pb-1 "
                                          }`}
                                        alt="express"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                    {/* )} */}

                    {/* series options */}
                    {productData["series_options"] &&
                      // productData?.pds?.length === 0 &&
                      productData["series_options"]?.map(
                        (series_option, key) =>
                          series_option?.series_option_id !== null && (
                            <div className="my-2 md:my-4">
                              <div className="flex justify-between">
                                <div className="flex justify-between items-center">
                                  <h3
                                    className="text-sm"
                                    style={{ color: "rgb(126, 133, 155)" }}
                                  >
                                    {`${series_option.series_option_name
                                      } ${":"}`}
                                  </h3>
                                  {series_option?.options?.map(
                                    (op_val) =>
                                      op_val.product_id === product_id &&
                                      !viewSeriesVal && (
                                        <span className="flex ml-1 font-semibold text-sm w-28">
                                          {" "}
                                          cc
                                          {op_val.name}
                                        </span>
                                      )
                                  )}
                                  <span
                                    id={key}
                                    className={`${viewSeriesVal ? "block" : "hidden"
                                      } ml-1 font-semibold text-sm w-28`}
                                  >
                                    {" "}
                                  </span>
                                </div>
                              </div>
                              <div className="flex fkex-wrap">
                                {series_option?.options?.map((option_val) => (
                                  <Link
                                    key={option_val?.product_id}
                                    to={{
                                      pathname:
                                        `${path}/product/` +
                                        option_val?.product_id
                                    }}
                                    className={`flex justify-center items-center w-20 mr-5 mb-5 border-dgrey border-2 hover:shadow cursor-pointer p-1 rounded-md
                                ${option_val.product_id === product_id &&
                                      " border-dblue"
                                      }
                              `}
                                    onClick={() =>
                                      setSeriesOpSelected(option_val.name)
                                    }
                                    onMouseOver={() => {
                                      handleHoveredSeries(key, option_val.name);
                                    }}
                                    onMouseLeave={() => handleLeavedSeries(key)}
                                  >
                                    <img
                                      src={option_val?.image}
                                      alt={option_val?.name}
                                      className="w-full"
                                      width={80}
                                      height={80}
                                    // placeholderSrc="https://www.sari3.com/ishtaridemo/product_placeholder.png"
                                    />
                                  </Link>
                                ))}
                              </div>
                            </div>
                          )
                      )}

                    {/* TIMER */}
                    {productData?.special_end !== 0 &&
                      typeof productData?.special_end !== typeof null &&
                      productData?.special_end !== "0" && (
                        <Timer
                          date={
                            (window.config["site-url"] ===
                              "https://www.ishtari.com.gh" ||
                              localStorage.getItem("site-local-name") ===
                              "ishtari-ghana") &&
                              productData?.special_end === "2023-01-10"
                              ? "2022-12-19"
                              : productData?.special_end
                          }
                        />
                      )}
                    {/*banner */}
                    {hasBannerEvent && hasBannerEvent?.thumb && (
                      <div className="mt-5">
                        <img
                          src={hasBannerEvent.thumb}
                          alt={hasBannerEvent.name}
                          className="w-full"
                        />
                      </div>
                    )}

                    {/* PDS */}
                    {/* {productData?.pds &&
                      productData.pds.length > 0 &&
                      productData["series_options"].length < 1 && (
                        <div className="my-2 md:my-4">
                          <h2 className="font-semibold text-d15 md:text-xl text-dblack mb-2">
                            In the same series
                          </h2>
                          <div className=" overflow-x-auto">
                            <div className="flex flex-wrap">
                              {productData.pds.map((product) => (
                                <Link
                                  key={product.product_id}
                                  to={{
                                    pathname:
                                      `${path}/product/` + product.product_id
                                  }}
                                  className={`flex justify-center items-center w-20 mr-5 mb-5 border-dgrey border-2 hover:shadow cursor-pointer p-1 rounded-md ${
                                    product.product_id === product_id &&
                                    " border-dblue"
                                  }`}
                                >
                                  <LazyLoadImage
                                    src={product.product_main_image}
                                    alt={product.product_name}
                                    className="w-full"
                                    width={80}
                                    height={80}
                                    placeholdersrc="https://www.sari3.com/ishtaridemo/product_placeholder.png"
                                  />
                                </Link>
                              ))}
                            </div>
                          </div>
                        </div>
                      )} */}


                    {width < 650 &&
                      <div className="flex    flex-col gap-1">
                        {productData?.series?.map((serie, index) => (
                          serie.is_primary && serie?.products?.length > 0 &&
                          <>

                            <div className=" flex flex-col  gap-3 justify-start">
                              <p className="font-semibold text-d15 md:text-xl text-dblack mb-2 before:w-full  underline-offset-8  decoration-dblack underline ">{serie.group_type ? serie.group_type : index === 0 ? "In the same series" : ""} </p>
                              <div className="  max-md:grid grid-cols-3   md:flex text-left flex-wrap gap-2  justify-start ">
                                {serie.products && serie.is_primary && serie.products.map((product) => (
                                  product.disabled && !stateAccount.admin ? <></> : <div
                                    onClick={(e) => {
                                      if (product.out_of_stock && !stateAccount.admin) {
                                        e.preventDefault()
                                      } else {
                                        history({
                                          pathname: `/${slugify(product.product_name)}/p=` + product.product_id,
                                        })
                                      }
                                    }
                                    }
                                    key={product.product_id}
                                    className={`flex  justify-center items-center py-1 px-1 max-w-[110px] w-full transition-all  border-2 hover:bg-dgrey  hover:shadow cursor-pointer  rounded-md ${product.product_id === product_id
                                      ? " border-dblue"
                                      : product.disabled ? "border-dbase" : "border-dgrey"
                                      }`}
                                  >
                                    <div className=" relative overflow-hidden flex gap-1 flex-col justify-between text-center ">
                                      <img
                                        title={product.type}
                                        src={product.image}
                                        alt={product.product_name}
                                        className="w-full  rounded-md"

                                      />
                                      <div className=" text-xs text-dblack">{product.type}</div>
                                      <div className=" flex flex-row gap-2 text-center justify-center">
                                        {!product.special == 0 && <div className=" text-dlabelColor text-xs line-through">{product.price}</div>}
                                        <div className=" text-sm text-dbase">{product.special == 0 ? product?.price : product.special}</div>

                                      </div>
                                      {product.out_of_stock && <div className=" top-0 left-0 right-0 bottom-0 flex justify-center text-center absolute  z-10 w-full h-full bg-dblack  bg-opacity-30">
                                        <div className="  w-full h-full relative   ">
                                          <div className="  bg-dbase w-[170px]  text-white  absolute top-[40%] -left-7 rotate-45 ">Out of stock</div>
                                        </div> </div>}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </>

                        ))}
                      </div>
                    }




                    {bundles && (
                      <div className="bg-dfooterbg py-2 px-4 mb-4 mt-8">
                        <h6 className="font-black text-sm mb-2 ml-2">
                          Frequently Bought Together
                        </h6>
                        <div className="bg-white">
                          <Slider {...productBundlesSetting}>
                            {bundles &&
                              bundles?.products?.map((product, i) => (
                                <div
                                  key={product.product_id}
                                  className="w-12/12 flex flex-row items-center"
                                >
                                  <div
                                    className={`${width < 650 && "w-10/12"}`}
                                  >
                                    <SingleProductNew
                                      item={product}
                                      i={i}
                                      len={bundles.products?.length}
                                    />
                                  </div>
                                  {i !== bundles?.products?.length - 1 && (
                                    <span className="text-3xl font-bold mt-2">
                                      +
                                    </span>
                                  )}
                                </div>
                              ))}
                          </Slider>
                        </div>

                        {bundles && (
                          <button
                            className="w-full h-12 text-center text-sm flex items-center justify-center font-semibold my-2 rounded bg-white text-dbluedark py-4 border-2 border-dbluedark"
                            onClick={() => {
                              addBundle(bundles);
                            }}
                          >
                            {countDownPointer === true &&
                              hasAddToCartError === false ? (
                              <div className="top-5 lds-ellipsis">
                                <div className="bg-dbluedark" />
                                <div className="bg-dbluedark" />
                                <div className="bg-dbluedark" />
                                <div className="bg-dbluedark" />
                              </div>
                            ) : (
                              !addingToCart && (
                                <span className="">
                                  Buy This Bundle For{" "}
                                  {bundles?.total_amount_after_discount}{" "}
                                </span>
                              )
                            )}
                          </button>
                        )}
                      </div>
                    )}
                    {width > 650 && (
                      <div className="my-4">
                        <a
                          className="flex justify-start"
                          href={`https://api.whatsapp.com/send?phone=${window.config["countryCode"] +
                            accountstate.wtspNumber
                            }&text=Hi%20there%20i%27m%20interested%20in%20${window.config["site-url"]
                            }/product/${product_id}`}
                        >
                          <div className="flex flex-row-reverse justify-around items-center bg-dgreen w-6/12 h-11 py-2 px-4 mt-3 text-white border-1 border-white rounded-md whitespace-nowrap">
                            <h1 className="text-md">Whatsapp Support</h1>
                            <i className="icon icon-whatsapp text-2xl w-9 h-9 rounded-full text-center  mr-4 "></i>
                          </div>
                        </a>
                      </div>
                    )}
                  </div>

                  {/* Extra */}
                  <div
                    className=" 
                  mt-4
                  md:mt-0
                  flex-row 
                  space-x-1 md:space-x-0
                  w-full
                  md:w-3/12
                  px-3
                  md:border-dinputBorder 
                  md:border-l
                  md:self-start
                  pb-4 md:pb-0
                  "
                  >
                    {accountstate.admin && (
                      <div className=" shadow-lg p-6 mb-4">
                        <div className="flex items-center justify-between">
                          <span className="text-xl">Quantity: </span>
                          <span
                            className={`text-2xl`}
                            style={{
                              color: productData.quantity > 5 ? "black" : "red"
                            }}
                          >
                            {productData.quantity}
                          </span>
                        </div>
                        <div className="flex items-center justify-between my-2">
                          <span className="text-xl">UPC: </span>
                          <span className="text-2xl">{productData.upc}</span>
                        </div>
                        <div className="flex items-center justify-between">
                          <span className="text-xl">SKU: </span>
                          <span className="text-2xl">{productData.sku}</span>
                        </div>
                        <br />
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`${window.config["admin-update-product"] + product_id
                            }&token=${accountstate.adminToken}`}
                          className="text-dblack  bg-dgrey px-6 py-2 rounded my-2 inline-block"
                        >
                          Edit Product
                        </a>
                      </div>
                    )}

                    {/* {productData?.no_refundable === true && (
                      <>
                        <div className="hidden md:flex-row w-1/2 md:w-full md:flex md:items-center text-dblack py-6 border-b border-dinputBorder">
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.8776 17.036C15.0639 17.5674 14.0921 17.8764 13.0466 17.8764C12.2313 17.8764 11.4612 17.6885 10.7761 17.3541C9.9253 16.9389 9.20453 16.2972 8.69273 15.5076C8.16748 14.6971 7.86243 13.731 7.86243 12.6921C7.86243 11.521 8.2502 10.4418 8.90457 9.57402C9.85159 8.3182 11.3544 7.50793 13.0466 7.50793C15.9098 7.50793 18.2308 9.82898 18.2308 12.6921C18.2308 13.4268 18.0783 14.1246 17.8037 14.7565C17.3974 15.6913 16.7231 16.4837 15.8776 17.036Z"
                              fill="#bf1b26"
                              stroke="#bf1b26" //this
                            />
                            <path
                              d="M14.8878 10.6484L11.2352 14.7798"
                              stroke="#eee"
                              strokeLinecap="round"
                            />
                            <path
                              d="M11.2352 10.6484L14.8878 14.7798"
                              stroke="#eee"
                              strokeLinecap="round"
                            />
                            <path
                              d="M3.84345 5.11316L13.0203 5.11316C17.2061 5.11316 20.5993 8.50637 20.5993 12.6921V12.6921C20.5993 16.8778 17.2061 20.2711 13.0203 20.2711H10.5951"
                              stroke="#bf1b26"
                              strokeLinecap="round"
                            />
                            <path
                              d="M5.29549 3.25525L3.40076 5.11319L5.29549 7.30388"
                              stroke="#bf1b26"
                              strokeLinecap="round"
                            />
                            <path
                              d="M8.18159 20.2711H6.19393"
                              stroke="#bf1b26"
                              strokeLinecap="round"
                            />
                          </svg>
                          <div className=" ml-2">
                            <span className="text-dgrey2 font-light text-sm">
                              This item cannot be exchanged or returned
                            </span>
                            <br />
                            <u
                              className="underline_effect cursor-pointer"
                              onClick={() => {
                                setNonrefundable(true);
                                handleReturnPolicy();
                              }}
                            >
                              Learn more
                            </u>
                          </div>
                        </div>

                        {nonrefundable && (
                          <div
                            className="fixed w-screen h-full min-h-screen top-0 left-0  bg-opacity-30 bg-dblack z-30"
                            onClick={() => setNonrefundable(false)}
                          ></div>
                        )}

                        <div>
                          <div
                            className={` top-0  bg-white right-0 sm:w-1/2 w-10/12  min-h-screen transform  fixed h-full z-40 
                              ease-in-out duration-300 overflow-y-scroll ${
                                nonrefundable
                                  ? "translate-x-0 "
                                  : "translate-x-full"
                              }`}
                          >
                            <div className="relative -top-9">
                              <div className="absolute z-40">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: unescapeHTML(
                                      DOMPurify.sanitize(
                                        returnPolicy?.description
                                      )
                                    )
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )} */}
                    {productData?.seller_id > 0 &&
                      productData.seller !== "" && (
                        <Link
                          to={
                            `${path}/${productData.seller}/s=` +
                            productData.seller_id
                          }
                          className="hidden md:flex items-center  cursor-pointer mr-5 md:mr-0 hover:opacity-80 py-2 md:py-6"
                        >
                          <i className="icon icon-shop text-dbase text-2xl mr-4" />
                          <span className="text-dblack text-sm">Sold by</span>

                          <h1 className="text-dblue underline font-semibold ml-2 text-sm">
                            {productData.seller}
                          </h1>
                        </Link>
                      )}
                    {productData?.market === "0" && (
                      <div className="hidden md:flex-row w-1/2 md:w-full md:flex md:items-center text-dblack py-6">
                        <img
                          src={ExpressImage}
                          className="w-16"
                          alt="express"
                        />
                        <div className="ml-2">
                          <h1 className="font-semibold text-sm">
                            Express delivery
                          </h1>
                          <p className="text-dgrey1 font-light text-d13">
                            Always in stock, ready to ship, faster delivery
                          </p>
                        </div>
                      </div>
                    )}

                    <div
                      className="
                                hidden
                                        md:flex  
                                w-full
                                items-center
                                text-dblack
                                border-t border-dinputBorder
                                py-2
                                md:py-6
                                
                            "
                    >
                      <i className="icon icon-bus text-dbase text-3xl mr-4" />
                      <div>
                        <h1 className="font-semibold text-sm">
                          TRUSTED SHIPPING
                        </h1>
                        <p className="text-dgrey1 font-light text-d13">
                          We will make sure to deliver your order within 4-7 days
                        </p>
                      </div>
                    </div>




                    {productData?.no_refundable === true ? (
                      <>
                        <div className="hidden md:flex-row w-1/2 md:w-full md:flex md:items-center text-dblack py-6">
                          <svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.8776 17.036C15.0639 17.5674 14.0921 17.8764 13.0466 17.8764C12.2313 17.8764 11.4612 17.6885 10.7761 17.3541C9.9253 16.9389 9.20453 16.2972 8.69273 15.5076C8.16748 14.6971 7.86243 13.731 7.86243 12.6921C7.86243 11.521 8.2502 10.4418 8.90457 9.57402C9.85159 8.3182 11.3544 7.50793 13.0466 7.50793C15.9098 7.50793 18.2308 9.82898 18.2308 12.6921C18.2308 13.4268 18.0783 14.1246 17.8037 14.7565C17.3974 15.6913 16.7231 16.4837 15.8776 17.036Z" fill="#BF212F" stroke="#BF212F" />
                            <path d="M14.8878 10.6484L11.2352 14.7798" stroke="#333848" stroke-linecap="round" />
                            <path d="M11.2352 10.6484L14.8878 14.7798" stroke="#333848" stroke-linecap="round" />
                            <path d="M3.84345 5.11316L13.0203 5.11316C17.2061 5.11316 20.5993 8.50637 20.5993 12.6921V12.6921C20.5993 16.8778 17.2061 20.2711 13.0203 20.2711H10.5951" stroke="#333848" stroke-linecap="round" />
                            <path d="M5.29549 3.25525L3.40076 5.11319L5.29549 7.30388" stroke="#333848" stroke-linecap="round" />
                            <path d="M8.18159 20.2711H6.19393" stroke="#333848" stroke-linecap="round" />
                          </svg>

                          <div className=" text-d14 ml-2">
                            <span className="text-dgrey2 font-light text-sm">
                              This item cannot be exchanged or returned
                            </span>
                            <br />
                            <u
                              className="underline_effect cursor-pointer"
                              onClick={() => {
                                setNonrefundable(true);
                                handleReturnPolicy();
                              }}
                            >
                              Learn more
                            </u>
                          </div>
                        </div>

                        {nonrefundable && (
                          <div
                            className="fixed w-screen h-full min-h-screen top-0 left-0  bg-opacity-30 bg-dblack z-30"
                            onClick={() => setNonrefundable(false)}
                          ></div>
                        )}

                        <div>
                          <div
                            className={` top-0  bg-white right-0 sm:w-1/2 w-10/12  min-h-screen transform  fixed h-full z-40 
                         ease-in-out duration-300 overflow-y-scroll ${nonrefundable ? "translate-x-0 " : "translate-x-full"
                              }`}
                          >
                            <div className="relative -top-9">
                              <div className="absolute z-40">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: unescapeHTML(
                                      sanitizeHTML(returnPolicy?.description)
                                    ),
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="hidden md:flex-row w-1/2 md:w-full md:flex md:items-center text-dblack py-6">
                          <svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.8775 17.036C15.0639 17.5674 14.0921 17.8764 13.0466 17.8764C12.2312 17.8764 11.4612 17.6885 10.7761 17.3541C9.92527 16.9389 9.2045 16.2972 8.6927 15.5076C8.16745 14.6971 7.8624 13.731 7.8624 12.6921C7.8624 11.521 8.25017 10.4418 8.90454 9.57402C9.85156 8.3182 11.3544 7.50793 13.0466 7.50793C15.9098 7.50793 18.2308 9.82898 18.2308 12.6921C18.2308 13.4268 18.0783 14.1246 17.8036 14.7565C17.3974 15.6913 16.7231 16.4837 15.8775 17.036Z" fill="#BF212F" stroke="#BF212F" />
                            <path d="M10.4005 12.6503L12.2848 14.5657L15.8437 10.819" stroke="#333848" stroke-linecap="round" />
                            <path d="M3.84341 5.11316L13.0203 5.11316C17.206 5.11316 20.5993 8.50637 20.5993 12.6921V12.6921C20.5993 16.8778 17.206 20.2711 13.0203 20.2711H10.595" stroke="#333848" stroke-linecap="round" />
                            <path d="M5.29546 3.25525L3.40073 5.11319L5.29546 7.30388" stroke="#333848" stroke-linecap="round" />
                            <path d="M8.18157 20.2711H6.19391" stroke="#333848" stroke-linecap="round" />
                          </svg>

                          <div className="text-d14 ml-2">
                            <span className="text-dgrey2 font-light text-sm">
                              This item is eligible for returns
                            </span>
                            <br />
                            <u
                              className="underline_effect cursor-pointer"
                              onClick={() => {
                                setNonrefundable(true);
                                handleReturnPolicy();
                              }}
                            >
                              Learn more
                            </u>
                          </div>
                        </div>

                        {nonrefundable && (
                          <div
                            className="fixed w-screen h-full min-h-screen top-0 left-0  bg-opacity-30 bg-dblack z-30"
                            onClick={() => setNonrefundable(false)}
                          ></div>
                        )}

                        <div>
                          <div
                            className={` top-0  bg-white right-0 sm:w-1/2 w-10/12  min-h-screen transform  fixed h-full z-40 
                              ease-in-out duration-300 overflow-y-scroll ${nonrefundable
                                ? "translate-x-0 "
                                : "translate-x-full"
                              }`}
                          >
                            <div className="relative -top-9">
                              <div className="absolute z-40">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: unescapeHTML(
                                      sanitizeHTML(returnPolicy?.description)
                                    ),
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}



                  </div>
                </div>
                {productData?.series && productData?.series?.length > 0 &&
                  productData.series.length > 0 &&
                  productData["series_options"].length < 1 && (
                    <div className=" border-t-4 border-dinputBorder bg-dinputBorder ">
                      <div className=" px-5 mobile:px-12 bg-white ">

                        <div className=" overflow-x-auto">
                          <div className="flex  flex-col gap-2">
                            {productData.series.map((serie, index) => (
                              !serie.is_primary && serie?.products?.length > 0 &&
                              <div className=" h-fit w-full  py-4   " >
                                <p className="font-semibold text-d15 md:text-xl text-dblack mb-2 before:w-full  underline-offset-8  decoration-dblack underline ">{serie.group_type ? serie.group_type : index === 0 ? "In the same series" : ""} </p>
                                <div className=" grid-cols-8 grid max-md:grid-cols-3 max-md:mx-auto py-3 w-fit  justify-center  gap-1">
                                  {serie.products && serie.products.map((product) => (
                                    product.disabled && !stateAccount.admin ? <></> :
                                      <div
                                        onClick={(e) => {
                                          if (product.out_of_stock && !stateAccount.admin) {
                                            e.preventDefault()
                                          } else {
                                            history({
                                              pathname: `/${slugify(product.product_name)}/p=` + product.product_id,
                                            })
                                          }
                                        }
                                        }
                                        key={product.product_id}
                                        className={`flex  justify-center items-center   max-w-[110px] w-full transition-all  border-2 hover:bg-dgrey  hover:shadow cursor-pointer  rounded-md ${product.product_id === product_id
                                          ? " border-dblue"
                                          : product.disabled ? "border-dbase" : "border-dgrey"
                                          }`}
                                      >
                                        <div className=" relative overflow-hidden flex gap-1 flex-col justify-between text-center ">
                                          <img
                                            title={product.type}
                                            src={product.image}
                                            alt={product.product_name}
                                            className="w-full  rounded-md"
                                          />
                                          <div className=" text-sm text-dblack">
                                            {product.type}
                                          </div>
                                          <div className=" flex flex-row gap-2 text-center justify-center">
                                            <div className=" flex flex-col ">
                                              <div className=" text-md text-dbase">
                                                {product.special}
                                              </div>
                                              <div className={` text-dgreyBlack text-d11 ${product.special == '' ? "" : "line-through"} `}>
                                                {product.price}
                                              </div>
                                            </div>
                                          </div>
                                          {product.out_of_stock && (
                                            <div className=" top-0 left-0 right-0 bottom-0 flex justify-center text-center absolute  z-10 w-full h-full bg-dblack  bg-opacity-30">
                                              <div className="  w-full h-full relative   ">
                                                <div className="  bg-dbase w-[195px]  text-white  absolute top-[40%] -left-10 rotate-45 ">
                                                  Out of stock
                                                </div>
                                              </div>{" "}
                                            </div>
                                          )}
                                        </div>
                                      </div>


                                  ))}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
              {/* Product Description */}
              {width < 650 && (
                <div className="my-4 container">
                  <a
                    className=""
                    href={`https://api.whatsapp.com/send?phone=${window.config["countryCode"] + accountstate.wtspNumber
                      }&text=Hi%20there%20i%27m%20interested%20in%20${window.config["site-url"]
                      }/product/${product_id}`}
                  >
                    <div className=" flex justify-around items-center bg-dgreen w-8/12 py-2 px-4 text-white">
                      <h1 className="text-md">Whatsapp Support</h1>
                      <i className="icon icon-whatsapp text-2xl w-9 h-9 rounded-full text-center  mr-4 "></i>
                    </div>
                  </a>
                </div>
              )}
              <div
                ref={lastElementRef}
                className="border-t-8 border-dinputBorder bg-dinputBorder"
                onScroll={() => setScroll(!scroll && true)}
              ></div>
              {width < 650 && (
                <div className="my-2 container bg-white">
                  <div
                    className="flex justify-between items-center"
                    onClick={() => setIsDetails((prev) => !prev)}
                  >
                    <h1 className="font-semibold text-xl py-2 text-dblack mb-1">
                      Product Details
                    </h1>
                    <i
                      className={
                        !isDetails
                          ? "icon icon-angle-right text-dgrey1 text-2xl transition-all"
                          : "icon icon-angle-down text-dgrey1 text-2xl transition-all"
                      }
                    ></i>
                  </div>

                  {isDetails && (
                    <p
                      onClick={() => setShowReview(false)}
                      className={`text-d20 px-2`}
                    >
                      Description
                    </p>
                  )}
                  <div
                    className={!isDetails ? "hidden" : "block"}
                    id="desc"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(productData.description)
                    }}
                  />
                  {isDetails && productData?.attribute_groups.length > 0 && (
                    <div className=" bg-white mb-4  ">
                      <div className="px-5  flex  flex-col items-center justify-center align-middle pb-5 ">
                        <div className="flex  space-x-10 text-d16 text-left w-full pt-5">
                          <p
                            onClick={() => setShowReview(false)}
                            className={`text-d20`}
                          >
                            Features
                          </p>
                        </div>

                        <div className="pt-5 w-full">
                          <table className=" text-d14 text-dgrey1 w-full">
                            <tbody>
                              {productData?.attribute_groups?.map((grp) => (
                                <tr className="even:bg-dgrey">
                                  <td className="px-2.5 w-1/2 py-1">
                                    {grp.name}
                                  </td>
                                  <td className="px-1">
                                    {grp?.attribute?.map((attr) => attr.name)}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                        <div></div>
                      </div>
                    </div>
                  )}
                </div>
              )}


              {width > 650 && (
                <div className="flex px-6 ml-4 flex-col gap-1">
                  {productData?.series?.map((serie, index) => (
                    serie.is_primary && serie?.products?.length > 0 &&
                    <>

                      <div className={`flex flex-col  gap-3 justify-start ${width > 1440 ? '  mx-60' : ''}`}>
                        <p className="font-semibold text-d15 md:text-xl text-dblack mb-2 before:w-full  underline-offset-8  decoration-dblack underline ">{serie.group_type ? serie.group_type : index === 0 ? "In the same series" : ""} </p>
                        <div className="  max-md:grid grid-cols-3   md:flex text-left flex-wrap gap-2  justify-start ">
                          {serie.products && serie.is_primary && serie.products.map((product) => (
                            product.disabled && !stateAccount.admin ? <></> : <div
                              onClick={(e) => {
                                if (product.out_of_stock && !stateAccount.admin) {
                                  e.preventDefault()
                                } else {
                                  history({
                                    pathname: `/${slugify(product.product_name)}/p=` + product.product_id,
                                  })
                                }
                              }
                              }
                              key={product.product_id}
                              className={`flex  justify-center items-center py-1 px-1 max-w-[110px] w-full transition-all  border-2 hover:bg-dgrey  hover:shadow cursor-pointer  rounded-md ${product.product_id === product_id
                                ? " border-dblue"
                                : product.disabled ? "border-dbase" : "border-dgrey"
                                }`}
                            >
                              <div className=" relative overflow-hidden flex gap-1 flex-col justify-between text-center ">
                                <img
                                  title={product.type}
                                  src={product.image}
                                  alt={product.product_name}
                                  className="w-full  rounded-md"

                                />
                                <div className=" text-xs text-dblack">{product.type}</div>
                                <div className=" flex flex-row gap-2 text-center justify-center">
                                  {!product.special == 0 && <div className=" text-dlabelColor text-xs line-through">{product.price}</div>}
                                  <div className=" text-sm text-dbase">{product.special == 0 ? product?.price : product.special}</div>

                                </div>
                                {product.out_of_stock && <div className=" top-0 left-0 right-0 bottom-0 flex justify-center text-center absolute  z-10 w-full h-full bg-dblack  bg-opacity-30">
                                  <div className="  w-full h-full relative   ">
                                    <div className="  bg-dbase w-[170px]  text-white  absolute top-[40%] -left-7 rotate-45 ">Out of stock</div>
                                  </div> </div>}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>

                  ))}
                </div>

              )}





              <div className={` w-full bg-white `}>
                <div className="my-1 bg-white mb-4 container px-4 my-content">
                  {width > 650 && (
                    <div className="flex px-6">
                      <h2
                        onClick={() => setShowReview(false)}
                        className={`${!showReview && "border-b-4 border-dblue "
                          }  font-semibold text-xl text-dblack cursor-pointer py-4`}
                      >
                        Product Information
                      </h2>
                    </div>
                  )}
                  {width > 650 && (
                    <div className="container  px-5  bg-white">
                      {/* <p
                      onClick={() => setShowReview(false)}
                      className={`${
                        !showReview &&
                        "border-b-4 border-dblue scale-110 transform ease-in-out duration-300"
                      } hidden : mobile:block font-semibold text-xl text-dblack cursor-pointer py-4`}
                    >
                      Product Information
                    </p> */}
                      <div className="flex bg-white">
                        <div
                          className={` ${productData?.attribute_groups.length > 0 &&
                            width > 650
                            ? "w-1/2"
                            : "w-full"
                            }  bg-white mb-4  px-6 my-content  text-d14 pb-2 `}
                        >
                          <div className="flex  space-x-10 text-d16 text-left w-full pt-5">
                            <p
                              onClick={() => setShowReview(false)}
                              className={`text-d20`}
                            >
                              Description
                            </p>
                          </div>
                          <div
                            id="desc"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                productData.description
                              )
                            }}
                          />{" "}
                        </div>

                        {productData?.attribute_groups.length > 0 && (
                          <div className=" bg-white mb-4  w-1/2 ml-12 ">
                            <div className="px-5  flex  flex-col items-center justify-center align-middle pb-5 ">
                              <div className="flex  space-x-10 text-d16 text-left w-full pt-5">
                                <p
                                  onClick={() => setShowReview(false)}
                                  className={`text-d20`}
                                >
                                  Features
                                </p>
                              </div>

                              <div className="pt-5 w-full">
                                <table className=" text-d14 text-dgrey1 w-full">
                                  <tbody>
                                    {productData?.attribute_groups?.map(
                                      (grp) => (
                                        <tr className="even:bg-dgrey">
                                          <td className="px-2.5 w-1/2 py-1">
                                            {grp.name}
                                          </td>
                                          <td className="px-1">
                                            {grp?.attribute?.map(
                                              (attr) => attr.name
                                            )}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>

                              <div></div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {loader ? (
                  <PointsLoader />
                ) : (
                  <>
                    <div
                      className=" border-t-8 border-dinputBorder  "
                      ref={titleRef} id="titleRef"
                    ></div>
                    {productData2?.product_reviews && (

                      <div ref={titleRef} className="container mobile:px-5 md:px-10">
                        <div className="flex space-x-10  border-b border-dplaceHolder   ">
                          <p
                            className={`${
                              // showReview &&
                              ""
                              }  cursor-pointer text-2xl  font-extrabold text-dblackk   py-4`}
                          >
                            Product Ratings & Reviews
                          </p>
                        </div>


                        <div className=" flex md:flex-row flex-col gap-5 justify-between ">
                      <div className=" flex flex-col gap-6 w-full md:w-[35%] px-5  border-r mt-5 border-dinputBorder">
                        <h2 className=" text-xl font-extrabold ">
                          Overall Rating
                        </h2>
                        <div className=" flex flex-col justify-start text-left">
                          <div className="font-bold text-xxl text-dRate ">
                            {productData2.product_reviews?.avg_rating?.toFixed(
                              1
                            ) || "0.0"}
                          </div>
                          <div className="grid place-items-left">
                            <StarRatings
                              starDimension="20px"
                              starEmptyColor="#e3e3e3"
                              starRatedColor="#f5a523"
                              starSpacing="1px"
                              rating={productData2?.product_reviews?.avg_rating}
                            />
                          </div>
                          <div className=" text-sm md:text-sm  text-dlabelColor">
                            Based on{" "}
                            {productData2?.product_reviews?.totals
                              ? productData2?.product_reviews?.totals
                              : "0"}{" "}
                            ratings
                          </div>
                          <div className=" flex flex-col-reverse">
                            {Object.entries(
                              productData2?.product_reviews?.nb_of_review
                            ).map(([rating, percentage]) => (
                              <div className=" flex flex-row justify-start gap-2 w-full ">
                                <span className=" w-[3%]">{rating}</span>
                                <FaStar className="my-auto w-[5%]" />
                                <div class=" w-[75%]  bg-dplaceHolder my-auto rounded-full h-1.5 ">
                                  <div
                                    class={`bg-dRate h-1.5 rounded-full `}
                                    style={{
                                      width: `${percentage.toFixed(0)}%`,
                                    }}
                                  ></div>
                                </div>
                                <span className=" w-[10%]">
                                  {percentage.toFixed(0)}%
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div className="p-1 py-2">
                              <div className="flex">
                                <div className="">
                                  <img
                                    src={reviewimg}
                                    alt={reviewimg}
                                    className="w-5 rounded-xl"
                                  />
                                </div>
                                <div className="font-semibold px-4">
                                  {" "}
                                  How do I review this product?
                                </div>
                              </div>
                              <div className="text-d12 text-dlabelColor ">
                                If you recently purchased this product from ishtari,
                                you can add Review.
                              </div>
                            </div>
                            <div className="p-1 py-2 ">
                              <div className="flex ">
                                <div className="">
                                  <img
                                    src={reviewimg1}
                                    alt={reviewimg1}
                                    className="w-5 rounded-xl"
                                  />
                                </div>
                                <div className="font-semibold px-4">
                                  {" "}
                                  Where do the reviews come from?
                                </div>
                              </div>
                              <div className="text-d12 text-dlabelColor">
                                Our verified reviews are from ishtari customers who
                                purchased the product and submitted a review
                              </div>
                            </div>

                          </div>

                      <div className=" flex flex-col gap-6  w-full md:w-[65%] px-3 text-left border-r mt-5 border-dinputBorder ">
                        {reviews ? (
                          <>
                            {" "}
                            <div className=" w-full flex flex-col  md:flex-row justify-start max-md:gap-2 md:justify-between">
                              {/* {data?.product_reviews?.totals > 0 && ( */}
                              <div className="font-bold text-xl text-left ">
                                {data?.product_reviews?.totals} Reviews
                              </div>
                              {/* )} */}
                              <div className="  flex flex-row max-md:justify-start md:justify-end max-sm:gap-1 md:gap-2">
                                <div className="relative">
                                  <div
                                    onClick={() =>
                                      setShowReviewFiltersStars(
                                        !showReviewFiltersStars
                                      )
                                    }
                                    className="py-2.5 px-6 border border-[#dadce3] md:w-44 max-sm:w-36 pr-light cursor-pointer flex justify-center items-center gap-2 rounded-sm"
                                  >
                                    <div>All Stars</div>
                                    <BsChevronDown />
                                  </div>
                                  {showReviewFiltersStars && (
                                    <div className="bg-white absolute border border-dgrey shadow-lg max-sm:w-36 md:w-44 z-10">
                                      {productData2?.product_reviews?.filter_product_reviews.filterStars?.map(
                                        (filter, index) => (
                                          <div
                                            key={index}
                                            className={`py-2.5 px-6 whitespace-nowrap cursor-pointer border-b border-dinputBorder  ${
                                              reviewFilter.value ===
                                              filter.value
                                                ? "bg-dblue text-white"
                                                : "hover:bg-dgrey"
                                            }`}
                                            onClick={() => {
                                              setShowReviewFiltersStars(false);
                                              getProductPart2(
                                                filter.value,
                                                "filter_star"
                                              );
                                            }}
                                          >
                                            <div className=" flex flex-row justify-start   w-full">
                                              <span className=" mr-2">
                                                {filter.text}
                                              </span>
                                              {Array(parseInt(filter.text))
                                                .fill()
                                                .map((_, i) => (
                                                  <FaStar
                                                    className=" text-dRate my-auto"
                                                    key={i}
                                                  />
                                                ))}
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )}
                                </div>

                                <div className="relative">
                                  <div
                                    onClick={() =>
                                      setShowReviewFilters(!showReviewFilters)
                                    }
                                    className="py-2.5 px-6 border border-[#dadce3] md:w-44 max-sm:w-40  pr-light cursor-pointer flex justify-center items-center gap-2 rounded-sm"
                                  >
                                    <div>{reviewFilter.text}</div>
                                    <BsChevronDown />
                                  </div>
                                  {showReviewFilters && (
                                    <div className="bg-white absolute border border-dgrey shadow-lg max-sm:w-40 md:w-44 z-40">
                                      {productData2?.product_reviews?.filter_product_reviews.filterLikes?.map(
                                        (filter, index) => (
                                          <div
                                            key={index}
                                            className={`py-2.5 px-6 whitespace-nowrap cursor-pointer ${
                                              reviewFilter.value ===
                                              filter.value
                                                ? "bg-dblue text-white"
                                                : "hover:bg-dgrey"
                                            }`}
                                            onClick={() => {
                                              setReviewFilter({
                                                text: filter.text,
                                                value: filter.value,
                                              });
                                              setShowReviewFilters(false);
                                              getProductPart2(
                                                filter.value,
                                                "filter_product_reviews"
                                              );
                                            }}
                                          >
                                            {filter.text}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            {reviews?.map((r) => (
                              <div
                                className="border-b-2 flex flex-col gap-1 border-dinputBorder pb-2"
                                key={r.review_id}
                              >
                                <div className="mt-4 flex justify-start items-center flex-row space-x-2.5 ">
                                  <div
                                    className="flex rounded-full w-14 h-14 border-2 text-white  text-d22 items-center justify-center"
                                    style={{
                                      backgroundColor:
                                        color[
                                          r.name
                                            .replace(/\s+/g, "")
                                            .charAt(0)
                                            ?.toLowerCase()
                                        ] || "red",
                                      minWidth: "56px",
                                    }}
                                  >
                                    {r.name
                                      .replace(/\s+/g, "")
                                      .charAt(0)
                                      .toUpperCase()}{" "}
                                  </div>
                                  <div className="flex flex-col justify-start ">
                                    <div className="flex items-start gap-2">
                                      <p className="text-base font-bold pr-3 w-40 md:w-48">
                                        {r?.name}
                                      </p>
                                      {r.check_purchase && (
                                        <div className="flex items-center justify-center text-d12 border-l-2 border-dinputBorder ">
                                          <svg
                                            width="13"
                                            height="13"
                                            className="mr-2"
                                            viewBox="0 0 13 13"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M5.07262 9.64218L2.67767 7.24723L3.52189 6.403L5.07262 7.94775L9.0183 4.00206L9.86252 4.85227L5.07262 9.64218ZM6.2701 0.661133C2.96506 0.661133 0.282715 3.34348 0.282715 6.64851C0.282715 9.95355 2.96506 12.6359 6.2701 12.6359C9.57513 12.6359 12.2575 9.95355 12.2575 6.64851C12.2575 3.34348 9.57513 0.661133 6.2701 0.661133Z"
                                              fill="#3866DF"
                                            ></path>
                                          </svg>
                                          <p className="item-center justified-item mt-1">
                                            Verified Purchase
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                    <div className="flex -mt-1.5">
                                      <StarRatings
                                        starDimension="18px"
                                        size="13"
                                        starSpacing="1px"
                                        starEmptyColor="#e3e3e3"
                                        starRatedColor="#f5a523"
                                        rating={parseInt(r.rating)}
                                      />
                                    </div>

                                    <p
                                      className="text-sm leading-none  font-d11 pt-1"
                                      style={{ color: "rgb(189, 189, 189)" }}
                                    >
                                      {r?.date_added
                                        .replace("-", " ")
                                        .replace("-", " ")}
                                    </p>
                                  </div>
                                </div>
                                <div className="images flex flex-wrap gap-1 ">
                                  {r?.images.map((img, i) => (
                                    <div
                                      className="mr-2 cursor-pointer"
                                      key={i}
                                      onClick={() =>
                                        handleReviewsModal(img, i, r)
                                      }
                                    >
                                      <img
                                        src={img}
                                        alt={img}
                                        width={56}
                                        height={56}
                                        className="w-14 h-14 sm:w-20 sm:h-20"
                                        loading="lazy"
                                      />
                                    </div>
                                  ))}
                                </div>

                                <div
                                  className="text-sm leading-none"
                                  dangerouslySetInnerHTML={{
                                    __html: sanitizeHTML(r.text),
                                  }}
                                ></div>
                                {r.review_replies &&
                                  r.review_replies.length > 0 && (
                                    <div className="pl-4">
                                      <h3 className="text-sm text-dgrey1 font-semibold mb-2">
                                        Replies
                                      </h3>
                                      <ul className="list-disc list-inside">
                                        {r.review_replies.map((reply) => (
                                          <li
                                            className="bg-white border-l-4 border-dgrey1 p-2 mt-1 rounded-md text-dgrey1 text-sm flex justify-between"
                                            key={reply.id}
                                          >
                                            <span>{reply.comment}</span>
                                            {stateAccount.admin &&
                                              dataInformation.can_reply_to_product_reviews && (
                                                <button
                                                  onClick={() =>
                                                    handleDeleteReply(reply.id)
                                                  }
                                                  className="text-dbase ml-4"
                                                >
                                                  Delete
                                                </button>
                                              )}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  )}

                                {replies.review_id == r.review_id && replies.reviewreplies?.map((rep) => (
                                  <li
                                    className="bg-white border-l-4 ml-4 border-dgrey1 p-2 mt-1 rounded-md text-dgrey1 text-sm flex justify-between"
                                    key={rep.id}
                                  >
                                    <span>{rep.comment}</span>
                                    {stateAccount.admin &&
                                    dataInformation.can_reply_to_product_reviews &&(
                                    <button
                                    onClick={() => handleDeleteReply(rep.id)}
                                    className="text-dbase ml-4"
                                  >
                                    Delete
                                  </button>
                                  )}
                                  </li>
                                ))}
                                {r.show_more_replies && (
                                  <div className="flex justify-center mt-2">
                                    <button
                                      onClick={() =>
                                        showMoreReplies(r.review_id)
                                      }
                                      className="text-dblue hover:underline focus:outline-none flex items-center text-d12"
                                      disabled={loadingreply}
                                    >
                                      {loadingreply ? (
                                        <div className="w-5 h-5 relative">
                                          <Loader />
                                        </div>
                                      ) : (
                                        <>
                                          Show More Replies
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-4 w-4 ml-1"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              d="M5.23 7.21a.75.75 0 011.06-.02L10 10.67l3.71-3.48a.75.75 0 111.04 1.08l-4.25 4a.75.75 0 01-1.04 0l-4.25-4a.75.75 0 01-.02-1.06z"
                                              clipRule="evenodd"
                                            />
                                          </svg>
                                        </>
                                      )}
                                    </button>
                                  </div>
                                )}

                                <div className="flex items-center gap-5 mt-4 mb-2">
                                  <div
                                    onClick={() =>
                                      handleReviewLike(r.review_id)
                                    }
                                    className={`flex items-center gap-1 border ${
                                      alreadyLiked.includes(r.review_id)
                                        ? "border-dblue text-dblue"
                                        : "border-[#7E859B] text-[#7E859B]"
                                    }  w-max px-2 py-1 rounded-[4px] text-sm cursor-pointer`}
                                  >
                                    <AiOutlineLike />
                                    {likeLoading.bool &&
                                    likeLoading.id === r.review_id ? (
                                      <div className="py-1.5 pr-4">
                                        <DotsLoader />
                                      </div>
                                    ) : alreadyLiked.includes(r.review_id) ? (
                                      <div>{likeCounts[r.review_id]}</div>
                                    ) : (
                                      <div>
                                        Helpful{" "}
                                        {likeCounts &&
                                        likeCounts[r.review_id] !== "0"
                                          ? "(" + likeCounts[r.review_id] + ")"
                                          : ""}
                                      </div>
                                    )}
                                  </div>

                                  {stateAccount.admin &&
                                    dataInformation.can_reply_to_product_reviews && (
                                      <div className="flex items-center">
                                        <input
                                          className="border border-dgrey1 rounded-md px-2 py-1"
                                          type="text"
                                          value={replyTexts[r.review_id] || ""}
                                          onChange={(e) =>
                                            handleReplyTextChange(
                                              r.review_id,
                                              e.target.value
                                            )
                                          }
                                          placeholder="Enter your reply"
                                        />
                                        <button
                                          className="rounded-md bg-dblue mx-2 px-4 py-1 text-white flex items-center justify-center"
                                          onClick={() =>
                                            handleReplySubmit(r.review_id)
                                          }
                                          disabled={loadingReplies[r.review_id]}
                                        >
                                          {loadingReplies[r.review_id] ? (
                                            <DotsLoader />
                                          ) : (
                                            <svg
                                              className="h-5 w-5 transform rotate-45"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              strokeWidth="2"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            >
                                              <line
                                                x1="22"
                                                y1="2"
                                                x2="11"
                                                y2="13"
                                              />
                                              <polygon points="22 2 15 22 11 13 2 9 22 2" />
                                            </svg>
                                          )}
                                        </button>
                                      </div>
                                    )}

                                  {likedReviews?.map(
                                    (liked) =>
                                      liked === r.review_id && (
                                        <div
                                          className="flex items-center gap-1.5"
                                          key={r.review_id}
                                        >
                                          <AiOutlineCheck className="text-dgreen text-d18" />
                                          <p className="text-sm pr-light">
                                            Thanks for voting!
                                          </p>
                                        </div>
                                      )
                                  )}
                                </div>
                              </div>
                            ))}
                            {productData2?.product_reviews?.total_pages > 1 && (
                              <ReactPaginate
                                pageCount={Math.ceil(
                                  productData2?.product_reviews?.total_pages
                                )}
                                containerClassName={"product-pagination"}
                                onPageChange={pageSetter}
                                pageRangeDisplayed={-1}
                                marginPagesDisplayed={0}
                                previousLabel={
                                  <div
                                    className={`flex ${
                                      pageValue === 1 &&
                                      "pointer-events-none opacity-50"
                                    }`}
                                  >
                                    <IoIosArrowBack />{" "}
                                    <span className="text-d13 ml-1 text-dblack">
                                      Previous Page
                                    </span>{" "}
                                  </div>
                                }
                                activeClassName={"active-pagination-product"}
                                nextLinkClassName={"bg-dgreyPrev  w-32 pr-1"}
                                previousLinkClassName={
                                  "bg-dgreyPrev items-center	justify-center"
                                }
                                nextLabel={
                                  <div
                                    className={`flex ml-2 p-0 ${
                                      pageValue ===
                                        productData2?.product_reviews
                                          ?.total_pages &&
                                      "pointer-events-none opacity-50"
                                    }`}
                                  >
                                    {" "}
                                    <span className="text-d13 mr-1 text-dblack">
                                      Next Page
                                    </span>{" "}
                                    <IoIosArrowForward className="" />{" "}
                                  </div>
                                }
                              />
                            )}
                          </>
                        ) : (
                          <div className="flex items-center justify-center flex-col pt-16 sm:pt-20 text-dblack">
                            <img
                              width={width > 650 ? 180 : 120}
                              src={imageCompression}
                            />
                            <h2 className="md:text-2xl sm:text-xl mt-6 font-semibold">
                              Reviews will appear here once available!
                            </h2>
                            <h3 className="font-light md:text-md max-sm:text-sm">
                              {stateAccount.loged ? (
                                "Be the first to leave a review"
                              ) : (
                                <h3 className=" text-center">
                                  Only registered users can write reviews.
                                  Please
                                  <span
                                    onClick={openAuthForm}
                                    className="text-dbluedark cursor-pointer m-1 underline"
                                  >
                                    Login
                                  </span>
                                  or
                                  <span
                                    onClick={openAuthForm}
                                    className="text-dbluedark cursor-pointer m-1 underline"
                                  >
                                    Register
                                  </span>
                                </h3>
                              )}
                            </h3>
                          </div>
                        )}
                      </div>
                    </div>

                        <div className="">  {stateAccount.loged && (
                          <div className="mt-4 flex justify-start w-full mobile:w-unset items-center flex-row space-x-2.5">
                            <div
                              className={
                                width > 650
                                  ? "flex flex-col w-1/2 font-bold pt-1 "
                                  : "flex flex-col w-full font-bold pt-1 "
                              }
                            >
                              <div className="flex items-center">
                                <div
                                  className="flex rounded-full w-14 h-14 text-white  text-d22 items-center justify-center disable"
                                  style={{
                                    backgroundColor:
                                      color[
                                      stateAccount.username
                                        .replace(/\s+/g, "")
                                        .charAt(0)
                                        .toLowerCase()
                                      ],
                                    color:
                                      textColor[
                                      stateAccount.username
                                        .replace(/\s+/g, "")
                                        .charAt(0)
                                        .toLowerCase()
                                      ],
                                  }}
                                >
                                  {" "}
                                  {stateAccount.username
                                    .replace(/\s+/g, "")
                                    .charAt(0)
                                    .toUpperCase()}
                                </div>
                                <div className="flex flex-col ml-3">
                                  <div className="">
                                    {" "}
                                    {stateAccount.username}
                                  </div>
                                  <div className="flex">
                                    <StarRatings
                                      starDimension="18px"
                                      starEmptyColor="#e3e3e3"
                                      starRatedColor="#f5a523"
                                      starHoverColor="#f5a523"
                                      starSpacing="1px"
                                      isSelectable="true"
                                      rating={ratingCustomer}
                                      changeRating={changeRating}
                                    />
                                  </div>
                                </div>
                              </div>




                              <div>
                                <div className="flex my-3 ml-1.5">
                                  <div
                                    className={`xs:border-2 xs:border-dslate xs:border-dashed relative h-14 w-14  sm:h-20 sm:w-20  ${(handleFileLimit() && "opacity-50",
                                      !handleFileLimit() && "cursor-pointer")
                                      }`}
                                    onClick={() => handleImageUpload()}
                                  >
                                    <div className="add_images_upload">
                                      <BsPlusLg
                                        className={`w-4 h-4 text-dblue  ${handleFileLimit() && "opacity-50"
                                          }`}
                                      />
                                      <input
                                        type="file"
                                        id="fileUpload"
                                        multiple
                                        onChange={(e) => onFileChange(e)}
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        disabled={handleFileLimit()}
                                        className="hidden"
                                        ref={hiddenFileInput}
                                        accept="image/png, image/jpeg, image/jpg"
                                      />
                                    </div>
                                  </div>
                                  <div className="flex flex-wrap gap-1 justify-center">
                                    {ReviewImages?.slice(0, 5).map(
                                      (img, index) => (
                                        <div
                                          className="relative ml-2"
                                          key={Math.random()}
                                        >
                                          <img
                                            src={URL.createObjectURL(img)}
                                            width={80}
                                            height={80}
                                            style={{
                                              height: "80px",
                                              width: "80px",
                                            }}
                                            className="h-14 w-14 sm:h-20 sm:w-20"
                                            alt={URL.createObjectURL(img)}
                                          />
                                          <button
                                            className="absolute z-10 bottom-0 w-full align-middle"
                                            style={{
                                              backgroundColor: "#00000066",
                                            }}
                                            onClick={() =>
                                              setReviewImages(
                                                ReviewImages.filter(
                                                  (e) => e !== img
                                                )
                                              )
                                            }
                                          >
                                            <FaTrash className="w-4 h-4 my-1 mr-auto ml-auto text-white " />
                                          </button>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>

                                {exceededMaxnb && (
                                  <div className="text-dbase">
                                    Number of selected images exceeds
                                    maxNumber "5"
                                  </div>
                                )}
                                {exceededSizeLimitErr && (
                                  <div className="text-dbase">
                                    The total size of selected images exceeds
                                    the limit of 2MB
                                  </div>
                                )}
                              </div>
                              {required && (
                                <span className="text-dbase text-d13 pt-1">
                                  {required}
                                </span>
                              )}
                                                          {imagenotification && (
                              <span className="text-dbase text-d13 pt-1">
                                {imagenotification}
                              </span>
                            )}
                              <div className="flex pt-2">
                                <input
                                  type="text"
                                  className={
                                    width > 650
                                      ? "rounded w-full px-2 border-2 border-dinputBorder"
                                      : "rounded w-full px-2 border-2 border-dinputBorder"
                                  }
                                  ref={textRef}
                                  placeholder="Write a comment…"
                                />
                                <button
                                  className="rounded bg-dblue mx-2 pl-2 pr-4 py-3 text-white"
                                  onClick={() => addReview()}
                                >
                                  <svg
                                    className="h-6 w-6  transform rotate-45	"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    {" "}
                                    <line
                                      x1="22"
                                      y1="2"
                                      x2="11"
                                      y2="13"
                                    />{" "}
                                    <polygon points="22 2 15 22 11 13 2 9 22 2" />
                                  </svg>{" "}
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                        </div>
                      </div>


                    )}
                    {productData2?.admin_product_reviews && (
                      <div
                        ref={titleRef} id="titleRef"
                        className={` container ${"show"
                          // window.config["site-url"] === "https://www.ishtari.com"
                          //   ? "show"
                          // : "hidden "
                          }`}
                      >
                        <div className="flex space-x-10 px-6  mx-2 ">
                          <h2
                            className={`${
                              // showReview &&
                              "border-b-4 border-dblue scale-110 transform ease-in-out duration-300"
                              }  font-semibold cursor-pointer text-xl  py-4`}
                          >
                            Product Reviews
                          </h2>

                          <span className="text-dbase pt-5">( Disabled )</span>
                        </div>

                        <div className=" flex flex-col gap-6 w-full md:w-[35%] px-5  border-r mt-5 border-dinputBorder">
                          <h2 className=" text-xl font-extrabold ">Overall Rating</h2>
                          <div className=" flex flex-col justify-start text-left">
                            <div className="font-bold text-xxl text-dRate ">
                              {productData2.product_reviews?.avg_rating?.toFixed(
                                1
                              ) || "0.0"}
                            </div>
                            <div className="grid place-items-left">
                              <StarRatings
                                starDimension="20px"
                                starEmptyColor="#e3e3e3"
                                starRatedColor="#f5a523"
                                starSpacing="1px"
                                rating={
                                  productData2?.product_reviews?.avg_rating
                                }
                              />
                            </div>
                            <div className=" text-sm md:text-sm  text-dlabelColor">
                              Based on{" "}
                              {productData2?.product_reviews?.totals
                                ? productData2?.product_reviews?.totals
                                : "0"}{" "}
                              ratings
                            </div>
                            <div className=" flex flex-col-reverse">
                              {Object.entries(productData2?.product_reviews?.nb_of_review).map(([rating, percentage]) => (

                                <div className=" flex flex-row justify-start gap-2 w-full ">
                                  <span className=" w-[3%]">{rating}</span>
                                  <FaStar className="my-auto w-[5%]" />
                                  <div class=" w-[75%]  bg-dplaceHolder my-auto rounded-full h-1.5 ">
                                    <div class={`bg-dRate h-1.5 rounded-full `} style={{
                                      width: `${percentage.toFixed(0)}%`
                                    }}></div>
                                  </div>
                                  <span className=" w-[10%]">{percentage.toFixed(0)}%</span>
                                </div>
                              ))}



                            </div>

                          </div>

                          <div className="p-1 py-2">
                            <div className="flex">
                              <div className="">
                                <img
                                  src={"/images/review.png"}
                                  alt={"reviewimg"}
                                  className="w-5 rounded-xl"
                                  width="10"
                                  height="10"
                                />
                              </div>
                              <div className="font-semibold px-4">
                                {" "}
                                How do I review this product?
                              </div>
                            </div>
                            <div className="text-d12 text-dlabelColor ">
                              If you recently purchased this product from ishtari,
                              you can add Review.
                            </div>
                          </div>
                          <div className="p-1 py-2 ">
                            <div className="flex ">
                              <div className="">
                                <img
                                  src={reviewimg1}
                                  alt={reviewimg1}
                                  className="w-5 rounded-xl"
                                />
                              </div>
                              <div className="font-semibold px-4">
                                {" "}
                                Where do the reviews come from?
                              </div>
                            </div>
                            <div className="text-d12 text-dlabelColor">
                              Our verified reviews are from ishtari customers who
                              purchased the product and submitted a review
                            </div>
                          </div>

                        </div>

                        <div
                          className={
                            showReview
                              ? " bg-white  mb-2 container"
                              : "bg-white my-2 mx-1 container"
                          } // hiddem
                        >
                          <div className="grid gap-4 lg:grid-cols-3 pt-4">
                            <div className="flex p-1  border-dgreyRate">
                              <div className="flex ">
                                <div className="text-center">
                                  <div className="text-center font-bold text-d13">
                                    Overall Rating
                                  </div>
                                  <div className="font-bold text-xxl text-dRate">
                                    {productData2.admin_product_reviews?.avg_rating?.toFixed(
                                      1
                                    ) || "0.0"}
                                  </div>
                                  <div className="grid place-items-center  just auto-rows-max">
                                    <StarRatings
                                      isReadOnly={true}
                                      starClassName=""
                                      size="15"
                                      unit="float"
                                      containerClassName="flex "
                                      emptyColor="#e3e3e3"
                                      activeColor="#f5a523"
                                      initialRating={
                                        productData?.admin_product_reviews
                                          ?.avg_rating
                                      }
                                    />
                                  </div>
                                  <div className="pl-3">
                                    Based on{" "}
                                    {productData2?.admin_product_reviews?.totals
                                      ? productData2?.admin_product_reviews
                                        ?.totals
                                      : "0"}{" "}
                                    ratings
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="px-6 ">
                            <div className="ml-1"></div>

                            {!productData2?.product_reviews?.reviews &&
                              productData2?.product_reviews?.reviews?.length <
                              1 &&
                              (width >= 650 ? (
                                <div className="px-1 py-1 bg-dinputBorder w-1/4 font-light text-center text-d12 mt-3">
                                  There are no customer reviews or customer
                                  ratings.
                                </div>
                              ) : (
                                <div className="px-1 py-1 bg-dinputBorder w-full font-light text-center text-d12 mt-3">
                                  There are no customer reviews or customer
                                  ratings.
                                </div>
                              ))}
                            {productData?.product_reviews?.totals > 0 && (
                              <div
                                className="font-bold text-xl border-b border-dinputBorder px-4 pt-8 pb-2"
                                ref={commentRef}
                              >
                                {productData?.product_reviews?.totals} Customer
                                Reviews
                              </div>
                            )}
                            {/* {(reviews?.length > 0 ||
                      accountstate.loged) && (
                      <div className=" border-b-2 border-dinputBorder pt-2"  ref={commentRef}></div>
                    )} */}

                            <div className="mt-2">
                              {productData2?.admin_product_reviews.reviews?.map(
                                (r) => (
                                  <div className="border-b-2 border-dinputBorder pb-2">
                                    <div className="mt-4 flex justify-start items-center flex-row space-x-2.5 ">
                                      <div
                                        className="flex rounded-full w-14 h-14 border-2 text-white  text-d22 items-center justify-center"
                                        style={{
                                          backgroundColor:
                                            color[
                                            r.name
                                              .replace(/\s+/g, "")
                                              .charAt(0)
                                              .toLowerCase()
                                            ],
                                          minWidth: "56px"
                                        }}
                                      >
                                        {r.name
                                          .replace(/\s+/g, "")
                                          .charAt(0)
                                          .toUpperCase()}{" "}
                                      </div>
                                      <div className="flex flex-col justify-start ">
                                        <div className="flex">
                                          <p className="text-base font-bold pr-3 w-48">
                                            {r?.name}
                                          </p>
                                          {r.check_purchase && (
                                            <div className="flex items-center justify-center text-d12 border-l-2 border-dinputBorder pl-2 pr-3">
                                              <svg
                                                width="13"
                                                height="13"
                                                className=" mr-2"
                                                viewBox="0 0 13 13"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M5.07262 9.64218L2.67767 7.24723L3.52189 6.403L5.07262 7.94775L9.0183 4.00206L9.86252 4.85227L5.07262 9.64218ZM6.2701 0.661133C2.96506 0.661133 0.282715 3.34348 0.282715 6.64851C0.282715 9.95355 2.96506 12.6359 6.2701 12.6359C9.57513 12.6359 12.2575 9.95355 12.2575 6.64851C12.2575 3.34348 9.57513 0.661133 6.2701 0.661133Z"
                                                  fill="#3866DF"
                                                ></path>
                                              </svg>
                                              <p className="item-center justified-item mt-1">
                                                Verified Purchase
                                              </p>
                                            </div>
                                          )}
                                        </div>
                                        <div className="flex">
                                          <StarRatings
                                            isReadOnly={true}
                                            starClassName=""
                                            isSelectable={false}
                                            allowHover={false}
                                            size="13"
                                            containerClassName="flex "
                                            emptyColor="#e3e3e3"
                                            activeColor="#f5a523"
                                            initialRating={r.rating}
                                          />
                                        </div>

                                        <p
                                          className="text-sm leading-none text-dgrey1 font-d11 dark:text-white pt-1"
                                          style={{
                                            color: "rgb(189, 189, 189)"
                                          }}
                                        >
                                          {/* {r.reviews.text} */}
                                          {r?.date_added
                                            .replace("-", " ")
                                            .replace("-", " ")}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="images flex flex-wrap gap-1 my-4">
                                      {r?.images.map((img) => (
                                        <div className="mr-2">
                                          <img
                                            src={img}
                                            alt=""
                                            className="w-14 h-14 sm:w-20 sm:h-20"
                                          />
                                        </div>
                                      ))}
                                    </div>
                                    <div className="text-sm leading-none  dark:text-white pt-3 ">
                                      {r?.text}
                                    </div>
                                  </div>
                                )
                              )}
                              {productData2?.admin_product_reviews
                                ?.total_pages > 1 && (
                                  <ReactPaginate
                                    pageCount={Math.ceil(
                                      productData2?.admin_product_reviews
                                        ?.total_pages
                                    )}
                                    containerClassName={"product-pagination"}
                                    onPageChange={pageSetter}
                                    pageRangeDisplayed={-1}
                                    marginPagesDisplayed={0}
                                    previousLabel={
                                      <div
                                        className={`flex ${pageValue === 1 &&
                                          "pointer-events-none opacity-50"
                                          }`}
                                      >
                                        <IoIosArrowBack />{" "}
                                        <span className="text-d13 ml-1">
                                          Previous Page
                                        </span>{" "}
                                      </div>
                                    }
                                    activeClassName={"active-pagination-product"}
                                    nextLinkClassName={"bg-dgreyPrev  w-32 pr-1"}
                                    previousLinkClassName={
                                      "bg-dgreyPrev items-center	justify-center"
                                    }
                                    nextLabel={
                                      <div
                                        className={`flex ml-2 p-0 ${pageValue ===
                                          productData2?.admin_product_reviews
                                            ?.total_pages &&
                                          "pointer-events-none opacity-50"
                                          }`}
                                      >
                                        {" "}
                                        <span className="text-d13 mr-1">
                                          Next Page
                                        </span>{" "}
                                        <IoIosArrowForward className="" />{" "}
                                      </div>
                                    }
                                  />
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}{" "}
                  </>
                )}
              </div>
            </div>
            {/* End first row */}
            {/* Whatsapp Button For Mobile */}

            {/* Poduct Details for Mobile :) */}
            {/* {width < 650 && (
              <div className="my-2 container bg-white">
                <div
                  className="flex justify-between items-center"
                  onClick={() => setIsDetails((prev) => !prev)}
                >
                  <h1 className="font-semibold text-xl py-2 text-dblack mb-1">
                    Product Details
                  </h1>
                  <i
                    className={
                      !isDetails
                        ? "icon icon-angle-right text-dgrey1 text-2xl transition-all"
                        : "icon icon-angle-down text-dgrey1 text-2xl transition-all"
                    }
                  ></i>
                </div>
                <div
                  className={!isDetails ? "hidden" : "block"}
                  id="desc"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(productData.description)
                  }}
                />
              </div>
            )} */}
            {/* Related Product */}
            {/* {productData2?.product_related &&
              productData2?.product_related?.length > 0 && (
                <div>
                  <div className="border-t-8 border-dinputBorder bg-dinputBorder"></div>

                  <div
                    className={`mb-2 mt-4  px-8 ${
                      width > 1920 && "mt-10"
                    } md:mb-8 container`}
                  >
                    <h2 className="font-semibold text-xl py-2 text-dblack mb-1 md:mb-4">
                      Related products
                    </h2>
                    {width < 650 ? (
                      <Slider {...productMobile}>
                        {productData2.product_related.map((item, index) => {
                          return (
                            <div className="pr-2" key={item.product_id}>
                              <SingleProducts
                                // likedData={likedData}
                                item={item}
                              />
                            </div>
                          );
                        })}
                      </Slider>
                    ) : (
                      <Slider {...productSetting}>
                        {productData2.product_related.map((item) => (
                          <div className="pr-2" key={item.product_id}>
                            <SingleProducts item={item} />
                          </div>
                        ))}
                      </Slider>
                    )}
                  </div>
                </div>
              )} */}

            {/*End  Related Product */}
            {productData2?.smallest_cat_products &&
              // !loader &&
              productData2?.smallest_cat_products?.length > 0 && (
                <div className=" w-full px-6 bg-white  pt-1">
                  <div className="container pb-2 md:pb-8">
                    <h2 className="font-semibold text-xl text-dblack mb-4 pt-2 md:pt-8">
                      {productData2.product_categories[0]?.name}
                    </h2>
                    {width < 650 ? (
                      <Slider {...productMobile}>
                        {productData2.smallest_cat_products.map((item) => (
                          <SingleProducts item={item}></SingleProducts>
                        ))}
                      </Slider>
                    ) : (
                      <div>
                        <Slider {...moreSettings}>
                          {productData2?.smallest_cat_products?.map((item) => (
                            <SingleProducts item={item}></SingleProducts>
                          ))}
                        </Slider>
                      </div>
                    )}
                  </div>
                </div>
              )}


            <div className="border-t-8 border-dinputBorder bg-dinputBorder"></div>
            {productData2?.product_categories &&
              // !loader &&
              productData2?.product_categories?.length > 0 && (
                <div className="w-full px-6 bg-white  ">
                  <div className="container pb-2 md:pb-8">
                    <h2 className="font-semibold text-xl text-dblack mb-4 pt-2 md:pt-8">
                      More to explore
                    </h2>
                    {width < 650 ? (
                      <div className="flex overflow-x-scroll">
                        {productData2.product_categories?.map((category) => (
                          <Link
                            key={category.category_id}
                            to={`${path}/category/${category.category_id}`}
                            className="cursor-pointer hover:opacity-80 min-w-max mr-4"
                          >
                            <img
                              src={category.image}
                              alt={category.name}
                              className=" w-32 block mx-auto"
                            />
                            <p
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(category.name)
                              }}
                              className="text-center mt-4 font-semibold text-sm"
                            ></p>
                          </Link>
                        ))}
                      </div>
                    ) : (
                      <div>
                        <Slider {...moreSettings}>
                          {productData2?.product_categories?.map((category) => (
                            <Link
                              key={category.category_id}
                              to={`${path}/${category.name
                                .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                                .replace(/\s+/g, "-")
                                .replaceAll("%", parseInt("%"))
                                .replaceAll("/", "-")
                                .replaceAll("#", parseInt("#"))
                                .replace(/'/g, "")}/c=${category.category_id}`}
                              className="cursor-pointer hover:opacity-80  mr-4"
                            >
                              <img
                                src={category.image}
                                alt={category.name}
                                className=" w-32 block mx-auto"
                              />
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(category.name)
                                }}
                                className="text-center mt-4 font-semibold text-sm line-clamp-2"
                              ></p>
                            </Link>
                          ))}
                        </Slider>
                      </div>
                    )}
                  </div>
                </div>
              )}

            <div className="border-t-8 border-dinputBorder bg-dinputBorder"></div>


            {!loading &&
              accountstate?.loged &&
              // !loader &&
              productData2?.product_recentlyViewed &&
              productData2?.product_recentlyViewed?.length > 0 && (
                <div className="w-full px-6 bg-white  ">
                  <div className="container pb-2 md:pb-8">
                    <h2 className="font-semibold text-xl text-dblack mb-4 pt-2 md:pt-8">
                      Recently Viewed
                    </h2>
                    {width < 650 ? (
                      <Slider {...productMobile}>
                        {productData2?.product_recentlyViewed?.map((item) => {
                          return (
                            <div className="pr-2" key={item.product_id}>
                              <SingleProducts
                                // likedData={likedData}
                                item={item}
                              />
                            </div>
                          );
                        })}
                      </Slider>
                    ) : (
                      <Slider {...productSetting}>
                        {productData2?.product_recentlyViewed?.map((item) => {
                          return (
                            <div className="pr-2" key={item.product_id}>
                              <SingleProducts
                                // likedData={likedData}
                                item={item}
                              />
                            </div>
                          );
                        })}
                      </Slider>
                    )}
                  </div>
                </div>
              )}
          </div>
        )
      ) : (
        <ProductPlaceholder item={productHolder} />
      )}
      {showGroup && (
        <div className="w-full px-4">
          <div
            id="overlay"
            className="fixed  z-40 w-screen h-screen inset-0 bg-dblack bg-opacity-60"
          ></div>

          <div
            id="dialog"
            // className="fixed z-50 top-1/3 bg-white rounded-md px-8 py-6 space-y-5 drop-shadow-lg 
            //  left-1/2 transform -translate-x-1/2 
            //  w-11/12 sm:w-2/8 sm:left-1/3 sm:top-1/3"
            className={`fixed z-50 bg-white rounded-md px-8 py-6 space-y-5 drop-shadow-lg ${window.innerWidth > 650
              ? "left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-2/8"
              : "top-40 w-11/12"
              }`}
          >
            <button
              id="close"
              className=" ml-3 top-0 -mt-10 w-10 h-10 hover:bg-indigo-700 bg-dgreyRate cursor-pointer float-right rounded-full  font-semibold text-dbluegray"
              onClick={() => setShowGroup(false)}
            >
              X
            </button>
            <div className="flex w-full">
              <span className="text-l font-semibold w-1/2">Select Group</span>
              <button
                className=" font-semibold text-dblue text-right ml-4"
                onClick={() => deleteItemFromAllGroup()}
              >
                Remove from all
              </button>
            </div>
            <div className="flex flex-col py-2 border-t border-dinputBorder overflow-y-auto h-80">
              {GroupWishlist.map((p, i) => (
                <div
                  className="flex  mb-4 pb-2 border border-dinputBorder"
                  onClick={() => {
                    updateState(p.wishlist_group_id);
                  }}
                >
                  <label class="text-sm ml-3 font-medium text-gray-900 w-10/12 mt-1">
                    {p.name}
                  </label>
                  <input
                    name="wish"
                    id="checkbox-1"
                    aria-describedby="checkbox-1"
                    type="checkbox"
                    value={p.wishlist_group_id}
                    className="h-4 w-4 float-right mt-2 border-dgrey"
                    onClick={() => setValue(p.wishlist_group_id)}
                    checked={
                      checked?.indexOf(p.wishlist_group_id.toString()) > -1 &&
                      "checked"
                    }
                  />
                </div>
              ))}
            </div>

            <div
              className="flex  mb-4  bg-dgreyRate px-2 py-2"
              onClick={() => {
                setShowGroup(false);
                setShowModel(true);
              }}
            >
              <label class="flex text-sm  font-medium text-gray-900 w-10/12 ">
                <div className="text-sm rounded-full bg-white w-5 h-5 mr-1 text-center text-dgreyBlack">
                  +
                </div>
                Create New wishlist
              </label>
            </div>
            <div class=" justify-end border-t-2 border-dinputBorder p-2">
              <button
                id="close"
                class="px-5 py-2 w-full bg-dblue hover:bg-indigo-700 text-white cursor-pointer rounded-md"
                onClick={addToWishList}
              >
                Done
              </button>

              {/* <button
                  id="close"
                  class="px-5 ml-3 py-2 bg-dbase hover:bg-indigo-700 text-white cursor-pointer rounded-md"
                  onClick={() => setShowGroup(false)}
                >
                  Close
                </button> */}

              <div
                onClick={() => history("/account/wishlist")}
                className="text-dblue text-center w-full pt-3"
              >
                Go to Wishlist{" "}
              </div>
            </div>
          </div>
        </div>
      )}

      {showModel && (
        <div className="container">
          <div
            id="overlay"
            className="fixed  z-40 w-screen h-screen inset-0 bg-dblack bg-opacity-60"
          ></div>

          <div
            id="dialog"
            className={` fixed z-50 top-1/3  bg-white rounded-md px-8 py-6 space-y-5 drop-shadow-lg ${window.innerWidth > 650
              ? "left-1/3 top-1/3 w-1/3"
              : "top-1 w-10/12 "
              }`}
          >
            <button
              id="close"
              className=" ml-3 top-0 -mt-10 w-10 h-10 hover:bg-indigo-700 bg-dgreyRate cursor-pointer float-right rounded-full  font-semibold text-dbgrey hover:opacity-90"
              onClick={() => setShowModel(false)}
            >
              X
            </button>
            <span className="text-l font-semibold">New Group</span>

            <div className="py-1 border-t border-dinputBorder">
              <div className="text-dbase w-full">
                {result?.errors && result?.errors[0]?.errorMsg}
              </div>
              <div className="mt-5">
                <div className="input mb-6 required">
                  <label htmlFor=""> Name </label>{" "}
                  <input
                    // value={nameValue}
                    onChange={(event) => setName(event.target.value)}
                  />
                </div>
              </div>
              <div className="input mb-6 ">
                <label htmlFor=""> Description </label>
                <input
                  // value={descriptionValue}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </div>
            </div>
            <div class="flex justify-end">
              <button
                id="close"
                class="w-full px-5 py-1 bg-dblue hover:bg-indigo-700 text-white cursor-pointer rounded-md"
                onClick={() => addGroup()}
              >
                save
              </button>
              {/* <button
                id="close"
                class="px-5 ml-3 py-2 bg-dbase hover:bg-indigo-700 text-white cursor-pointer rounded-md"
                onClick={() => setShowModel()}
              >
                Close
              </button> */}
            </div>
          </div>
        </div>
      )}
      {/* {(window.config["site-url"] === "https://www.ishtari.com" ||
        localStorage.getItem("site-local-name") === "ishtari") && 
        !loading && <div className="border-t-8 mt-6 border-dinputBorder"></div>} */}
    </div>
  );
}

export default Productv2;

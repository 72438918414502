import { useContext } from "react";
import { AccountContext } from "../contexts/AccountContext";



export  const   HandleAuthForm=()=>{

    const [state, dispatch] = useContext(AccountContext);
     


    const openAuthForm=(type)=>{
      if(type === "switch"){
        dispatch({ type: "setShowListAcc", payload: true });
      }else{
        if(state.listAccCach.length == 0){
          dispatch({ type: "setisRegistrationFormOpen", payload: true });
        }else{
          dispatch({ type: "setShowListAcc", payload: true });
    }
  }
    }

    const closeAuthForm =()=>{
      dispatch({ type: "setisRegistrationFormOpen", payload: false });
      dispatch({ type: "setShowListAcc", payload: false });
    }
  



    return {openAuthForm,closeAuthForm}
}




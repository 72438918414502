import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import _axios from "../axios";
import { AccountContext } from "../contexts/AccountContext";
import { useWindowDimensions } from "./TopHeader";
import buildLink from "../urls";
import FooterV2 from "./FooterV2";
import { AiOutlineMail, AiOutlineTwitter } from "react-icons/ai";
import { FaQuestion } from "react-icons/fa";

import {
  FaLinkedinIn,
  FaFacebookF,
  FaInstagram,
  FaWhatsapp,
  FaTiktok,
} from "react-icons/fa";
import mastercard from "../assets/images/mastercard.svg";
import visa from "../assets/images/visa.svg";
import cash from "../assets/images/cash.svg";
import amex from "../assets/images/amex.svg";
import { CurrencyContext } from "../contexts/CurrencyContext";
import { BsHeadphones } from "react-icons/bs";

function Footer() {
  const { width } = useWindowDimensions();
  // const [info, setInfo] = useState([]);
  const info = useContext(CurrencyContext);
  const [accountstate] = useContext(AccountContext);
  const navigate = useNavigate();
  // async function getInfo() {
  //   _axios
  //     .get(buildLink("information", undefined, window.innerWidth))
  //     .then((response) => {
  //       try {
  //         const data = response.data.data.informations;
  //         setInfo(data);
  //       } catch (e) {}
  //     });
  // }

  // useEffect(() => {
  //   // getFooter()
  //   getInfo();
  // }, []);

  return (
    <div className="text-dblack border-t-8 border-dinputBorder bg-white">
      {/* <div className="border-t-12 border-dinputBorder bg-dinputBorder12"></div> */}

      {/* <div
        className=" bg-white  bg-opacity-5 w-screen "
        style={{ border: "0px solid #ccc" }}
      >
        <div className="container flex-row md:flex py-2 md:py-8  justify-between items-center">
          <div className="mx-4 mb-5">
            <h1 className="font-semibold text-sm text-center md:text-xl">
              We’re Always Here To Help
            </h1>
            <p className=" font-light text-xs text-center">
              Reach out to us through any of these support channels
            </p>
          </div>

          <div className="flex  mx-24 md:mx-0 mb-3  border-b md:border-0 border-dinputBorder border-opacity-20  items-center">
            <i className="icon icon-headphones text-xl w-9 h-9 rounded-full text-center border border-dinputBorder bg-white mr-4 leading-9"></i>
            <div className="flex flex-col items-center justify-center">
              <span className="text-sm">Phone Support</span>
              <span className="text-xl">{window.config["supportNumber"]}</span>
            </div>
          </div>

          <div className="flex mx-24 md:mx-0 mb-3 border-b md:border-0 border-dinputBorder border-opacity-20 items-center">
            <a
              href={`mailto:${window.config["send_email"]}`}
              className="flex items-center justify-center"
            >
              <i className="icon icon-mail text-xl w-9 h-9 rounded-full text-center border border-dinputBorder bg-white mr-4 leading-9"></i>

              <div className="flex flex-col items-center justify-center">
                <span className="text-sm">Email Support</span>
                <span className="text-xl">Send Mail</span>
              </div>
            </a>
          </div>

          <div className="flex mx-24  items-center">
            <a
              href={`https://api.whatsapp.com/send?phone=${window.config["countryCode"]}${window.config["supportNumber"]}&text=Hi%20there%20i%27m%20interested%20in%20${window.config["site-url"]}`}
            >
              <div className="flex items-center justify-center">
                <i className="icon icon-whatsapp text-xl w-9 h-9 rounded-full text-center border border-dinputBorder bg-white mr-4 leading-9"></i>
                <span className="text-xl">Whatsapp Us</span>
              </div>
            </a>
          </div>
        </div>
      </div> */}

      <div className="container">
        <div
          className=" flex flex-col md:flex-row md:gap-8 py-4 pt-4 px-5"
          style={{ border: "0px solid #ccc" }}
        >
          <div className="w-full md:w-4/12 flex flex-col justify-center items-center mb-4 mobile:mb-0 ">
            <p className="font-semibold text-sm  md:text-xl">
              We’re Always Here To Help
            </p>
            <p className=" font-light text-xs ">
              Reach out to us through any of these support channels
            </p>
          </div>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 items-center justify-items-center text-center">
            <div className="flex justify-start sm:justify-center items-center border-b md:border-0 border-dinputBorder border-opacity-20 mb-3 md:mb-0">
              <BsHeadphones className="w-8 h-8 rounded-full text-center border border-dinputBorder bg-white mr-4 leading-9 p-1" />
              <div className="flex flex-col items-center justify-center ">
                <span className="text-sm xs:text-d13 xs:w-24">
                  Phone Support
                </span>
                <div className="text-xl max-sm:text-md xs:text-d13 xs:mr-3">
                  {window.config["supportNumber"]}
                </div>
              </div>
            </div>

            <div className="flex justify-start md:justify-center md:mx-0 mb-3 border-b md:border-0 border-dinputBorder border-opacity-20 items-center">
              <a
                href={`mailto:${window.config["send_email"]}`}
                className="flex items-center justify-center"
              >
                <AiOutlineMail className="w-8 h-8 rounded-full text-center border border-dinputBorder bg-white mr-4 leading-9 p-1" />

                <div className="flex flex-col items-center justify-center">
                  <span className="text-sm xs:text-d13 xs:w-24">
                    Email Support
                  </span>
                  <span className="text-xl max-sm:text-md xs:text-d13 xs:mr-3">
                    Send Mail
                  </span>
                </div>
              </a>
            </div>

            <div className="flex justify-start md:justify-center items-center">
              <a
                href={`https://api.whatsapp.com/send?phone=${window.config["countryCode"]}${window.config["supportNumber"]}&text=Hi%20there%20i%27m%20interested%20in%20${window.config["site-url"]}`}
              >
                <div className="flex items-center justify-center">
                  <FaWhatsapp className="w-6 h-6 md:w-8 md:h-8 rounded-full text-center border border-dinputBorder bg-white mr-4 leading-9 " />

                  <span className="text-xl max-sm:text-md max-xl:text-md xs:text-d13 xs:w-20 xs:mr-3">
                    Whatsapp Us
                  </span>
                </div>
              </a>
            </div>

            {/* FAQ Support */}

            <div
              onClick={() => {
                navigate("/hc");
                window.scrollTo(0, 0);
              }}
              className="flex justify-start md:justify-center items-center cursor-pointer"
            >
              <FaQuestion className="w-6 h-6  md:w- md:h-8 rounded-full text-center border border-dinputBorder bg-white mr-4 leading-9 " />
              <span className="text-xl max-sm:text-md max-xl:text-md xs:text-d13 xs:w-20 xs:mr-3">
                FAQ
              </span>
            </div>
          </div>
        </div>
      </div>
      <div>{window.config["showMenu"] && <FooterV2 width={width} />}</div>

      {width < 800 ? (
        <div className="mt-0 proxima-font">
          {" "}
          <div className="border-t-8 mb-4 border-dinputBorder"></div>
          <div className="text-center text-sm font-bold mb-5">
            SHOP ON THE GO
          </div>
          <div className="flex justify-center space-x-3 ">
            <a href={window.config["appleStore"]}>
              <p className="appleStore px-10"></p>
            </a>
            <a href={window.config["googlePlay"]}>
              <p className="googlePlay"></p>
            </a>
          </div>
          <div className="text-sm text-center pb-4 font-bold mt-2">
            CONNECT WITH US
          </div>
          <div className="pb-4 mt-2 flex justify-center text-center">
            <a href={window.config["facebook"]}>
              <div className="facebook-border">
                {" "}
                <i
                  className={`icon icon-facebook facebook  ${
                    localStorage.getItem("site-local-name") === "flo" ||
                    window.location.host === "www.flo-lebanon.com"
                      ? "bg-Orangeflo"
                      : localStorage.getItem("site-local-name") === "aalbeit" ||
                        window.location.host === "www.aalbeit.com"
                      ? "bg-greenaalbeit"
                      : "bg-dbase"
                  }`}
                ></i>
              </div>
            </a>
            <a href={window.config["tiktok"]} className="flex">
              <div className="facebook-border">
                <i
                  className={`flex facebook ${
                    localStorage.getItem("site-local-name") === "flo" ||
                    window.location.host === "www.flo-lebanon.com"
                      ? "bg-Orangeflo"
                      : localStorage.getItem("site-local-name") === "aalbeit" ||
                        window.location.host === "www.aalbeit.com"
                      ? "bg-greenaalbeit"
                      : "bg-dbase"
                  }`}
                >
                  <FaTiktok />
                </i>
              </div>
            </a>

            <a href={window.config["instagram"]}>
              <div className="facebook-border">
                {" "}
                <i
                  className={`icon icon-instagram facebook  ${
                    localStorage.getItem("site-local-name") === "flo" ||
                    window.location.host === "www.flo-lebanon.com"
                      ? "bg-Orangeflo"
                      : localStorage.getItem("site-local-name") === "aalbeit" ||
                        window.location.host === "www.aalbeit.com"
                      ? "bg-greenaalbeit"
                      : "bg-dbase"
                  }`}
                ></i>
              </div>
            </a>
            <div href={window.config["linkedin"]}>
              <div className="facebook-border">
                {" "}
                <i
                  className={`flex facebook  ${
                    localStorage.getItem("site-local-name") === "flo" ||
                    window.location.host === "www.flo-lebanon.com"
                      ? "bg-Orangeflo"
                      : localStorage.getItem("site-local-name") === "aalbeit" ||
                        window.location.host === "www.aalbeit.com"
                      ? "bg-greenaalbeit"
                      : "bg-dbase"
                  }`}
                >
                  <FaLinkedinIn />
                </i>
              </div>
            </div>

            <a href={window.config["twitter"]}>
              <div className="facebook-border">
                {" "}
                <i
                  className={`flex facebook  ${
                    localStorage.getItem("site-local-name") === "flo" ||
                    window.location.host === "www.flo-lebanon.com"
                      ? "bg-Orangeflo"
                      : localStorage.getItem("site-local-name") === "aalbeit" ||
                        window.location.host === "www.aalbeit.com"
                      ? "bg-greenaalbeit"
                      : "bg-dbase"
                  }`}
                >
                  <AiOutlineTwitter />
                </i>
              </div>
            </a>
          </div>
          <div
            className="text-center font-extralight text-sm  pb-1"
            style={{ color: "rgb(126, 133, 155)" }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: `&copy ${new Date().getFullYear()} `,
              }}
            ></span>
            {window.config["short-name"]}. All Rights Reserved
          </div>
          <div className="flex mt-2 justify-center">
            {window.config["showVisaCard"] && (
              <div className="flex w-64 mt-2 justify-between">
                <img className="h-5 w-9" src={mastercard} />
                <img className="h-5 w-16" src={visa} />
                <img className="h-4 w-12" src={cash} />
                <img className="h-5 w-14" src={amex} />
              </div>
            )}
          </div>
          <div className=" pt-7 pb-4 text-center flex-wrap space-x-4 px-6 font-thin">
            {info?.data?.informations?.map(
              (i) =>
                i.status === "1" && (
                  <Link
                    key={i.id}
                    className=" text-sm"
                    to={`/information/${i.id}`}
                    style={{ color: "#404553" }}
                  >
                    {i.title}
                  </Link>
                )
            )}
          </div>
        </div>
      ) : (
        <div className="mt-4">
          <div className="flex items-center py-10 mx-20 justify-around">
            <div className=" text-center">
              <div className="text-md font-bold mb-3">SHOP ON THE GO</div>
              <div className="flex space-x-3 ">
                <a href={window.config["appleStore"]}>
                  <p className="appleStore px-10"></p>
                </a>
                <a href={window.config["googlePlay"]}>
                  <p className="googlePlay"></p>
                </a>
              </div>
            </div>
            <div className="flex-row">
              <div className="text-md text-center mb-3 font-bold ">
                CONNECT WITH US
              </div>
              <div className="text-center flex">
                <a href={window.config["facebook"]}>
                  <i
                    className={`flex facebook-desktop  ${
                      localStorage.getItem("site-local-name") === "flo" ||
                      window.location.host === "www.flo-lebanon.com"
                        ? "bg-Orangeflo"
                        : localStorage.getItem("site-local-name") ===
                            "aalbeit" ||
                          window.location.host === "www.aalbeit.com"
                        ? "bg-greenaalbeit"
                        : "bg-dbase"
                    }`}
                  >
                    <FaFacebookF />
                  </i>
                </a>
                <a href={window.config["tiktok"]}>
                  <i
                    className={`flex facebook-desktop ${
                      localStorage.getItem("site-local-name") === "flo" ||
                      window.location.host === "www.flo-lebanon.com"
                        ? "bg-Orangeflo"
                        : localStorage.getItem("site-local-name") ===
                            "aalbeit" ||
                          window.location.host === "www.aalbeit.com"
                        ? "bg-greenaalbeit"
                        : "bg-dbase"
                    }`}
                  >
                    <FaTiktok /> {/* Add TikTok Icon */}
                  </i>
                </a>
                <a href={window.config["twitter"]}>
                  {" "}
                  <i
                    className={`flex facebook-desktop  ${
                      localStorage.getItem("site-local-name") === "flo" ||
                      window.location.host === "www.flo-lebanon.com"
                        ? "bg-Orangeflo"
                        : localStorage.getItem("site-local-name") ===
                            "aalbeit" ||
                          window.location.host === "www.aalbeit.com"
                        ? "bg-greenaalbeit"
                        : "bg-dbase"
                    }`}
                  >
                    <AiOutlineTwitter />
                  </i>
                </a>
                <a href={window.config["instagram"]}>
                  <i
                    className={`flex facebook-desktop  ${
                      localStorage.getItem("site-local-name") === "flo" ||
                      window.location.host === "www.flo-lebanon.com"
                        ? "bg-Orangeflo"
                        : localStorage.getItem("site-local-name") ===
                            "aalbeit" ||
                          window.location.host === "www.aalbeit.com"
                        ? "bg-greenaalbeit"
                        : "bg-dbase"
                    }`}
                  >
                    <FaInstagram />
                  </i>
                </a>
                <div href={window.config["linkedin"]}>
                  {" "}
                  <i
                    className={`flex facebook-desktop  ${
                      localStorage.getItem("site-local-name") === "flo" ||
                      window.location.host === "www.flo-lebanon.com"
                        ? "bg-Orangeflo"
                        : localStorage.getItem("site-local-name") ===
                            "aalbeit" ||
                          window.location.host === "www.aalbeit.com"
                        ? "bg-greenaalbeit"
                        : "bg-dbase"
                    }`}
                  >
                    <FaLinkedinIn />
                  </i>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`flex items-center justify-between ${
              width > 1170 ? "px-20" : width < 900 ? "px-5" : "px-10"
            } py-7 bg-dgreyProduct bg-opacity-5 w-screen`}
          >
            <div
              className="text-center font-extralight text-sm "
              style={{ color: "rgb(126, 133, 155)" }}
            >
              <span dangerouslySetInnerHTML={{ __html: "&copy" }}></span>{" "}
              {new Date().getFullYear()} {window.config["short-name"]}. All
              Rights Reserved
            </div>
            <div className="flex ">
              {window.config["showVisaCard"] && (
                <div className={` flex mt-2 `}>
                  <ul className="flex px-6 flex-wrap justify-center items-center">
                    {" "}
                    <li className="flex rounded-sm items-center justify-center text-center m-2 ">
                      <img className={`h-5 w-9`} src={mastercard} />
                    </li>
                    <li className="flex rounded-sm items-center justify-center text-center m-2 ">
                      {" "}
                      <img className={`h-5 w-16`} src={visa} />
                    </li>
                    <li className="flex rounded-sm items-center justify-center text-center m-2 ">
                      {" "}
                      <img className={`h-4 w-12`} src={cash} />
                    </li>
                    <li className="flex rounded-sm items-center justify-center text-center m-2 ">
                      {" "}
                      <img className={`h-5 w-14`} src={amex} />
                    </li>
                  </ul>
                </div>
              )}
            </div>

            <div className="flex text-center space-x-4 text-d12 font-thin">
              {info?.data?.informations?.map((i) => {
                return (
                  i.status === "1" && (
                    <Link
                      key={i.id}
                      className="text-d12"
                      to={`/information/${i.id}`}
                      style={{ color: "#404553" }}
                    >
                      {i.title}
                    </Link>
                  )
                );
              })}
              <Link
                className="text-d12"
                to={`/contact`}
                style={{ color: "#404553" }}
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
<style></style>;

export default Footer;

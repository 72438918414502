import { Link } from "react-router-dom";
import { path } from "../urls";
import { BsFillCartCheckFill } from 'react-icons/bs';
import { MdAvTimer } from 'react-icons/md';
import { RiCoupon3Fill } from "react-icons/ri";
import { FaCheckCircle, FaTv } from "react-icons/fa";
import { MdFeedback } from "react-icons/md";
import { FaWallet } from "react-icons/fa";
import { CiBookmarkCheck } from 'react-icons/ci';
import { MdHourglassFull } from 'react-icons/md';
import { BsStarFill } from "react-icons/bs";
import _axios from "../axios";
import buildLink from "../urls";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { FaSignOutAlt } from "react-icons/fa";
import { AccountContext } from "../contexts/AccountContext";
import { useContext, useEffect, useState } from "react";

function Account(props) {
    const history = useNavigate();
    const [state, dispatch] = useContext(AccountContext);



    async function logout(type) {
        dispatch({ type: "setLoading", payload: true });
        await _axios.post(buildLink("logout")).then(() => {

            dispatch({ type: "setSeller", payload: false });
            Cookies.remove("seller_id");
            window.location.reload();
        });
    }
    return (
        <div className="flex flex-col p-4 md:hidden space-y-2">
            {/* Account Management Section */}
            <div className="flex flex-col space-y-2 bg-white rounded-lg p-4 shadow-sm">
                <h2 className="font-bold text-lg border-b pb-2 border-b-dgreyQtyProduct">Account Management</h2>
                <div className="flex justify-between items-start"> {/* Make the container a flexbox */}
                    <div className="flex space-x-4"> {/* Space between the links */}
                        <Link to={`${path}/account/profile`} className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:p-2">
                            <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                                <i className="icon icon-user-solid text-2xl text-black"></i>
                            </div>
                            <span className="font-semibold text-black text-sm">Profile</span>
                        </Link>
                        <Link to={`${path}/account/addresses`} className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:p-2">
                            <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                                <i className="icon icon-location text-2xl text-black"></i>
                            </div>
                            <span className="font-semibold text-black text-sm">Addresses</span>
                        </Link>
                    </div>
                    <div className="p-4 pt-2 bg-white">
                        <button
                            className={`flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover:bg-dbluedark   py-2`} // Adjusted styles
                            onClick={() => {
                                logout();
                                history('/');
                            }}
                        >
                            <FaSignOutAlt className="text-2xl mb-3" />
                            <span className="font-semibold text-black text-sm">Sign Out</span>
                        </button>
                    </div>


                </div>
            </div>


            {/* Status Section */}
            <div className="flex flex-col space-y-2 bg-white rounded-lg p-4 shadow-sm">
                <h2 className="font-bold text-lg border-b border-b-dgreyQtyProduct pb-2">Status</h2>
                <div className="flex space-x-4">
                    <Link to="/account/orders?status=completed">
                        <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover: p-2">
                            <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                                {/* Icon for Completed */}
                                <CiBookmarkCheck className="text-2xl text-black" />
                            </div>
                            <span className="font-semibold text-black text-sm">Completed</span>
                        </div>
                    </Link>


                    <Link to="/account/orders?status=processing">
                        <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover: p-2">
                            <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                                {/* Icon for Processing */}
                                <MdHourglassFull className="text-2xl text-black" />
                            </div>
                            <span className="font-semibold text-black text-sm">Processing</span>
                        </div>
                    </Link>


                    <Link to="/account/orders?status=trash">
                        <div className="flex flex-col items-center justify-center h-24 rounded-lg transition duration-200 hover: p-2">
                            <div className="flex justify-center items-center w-10 h-10 rounded-full mb-1">
                                {/* Icon for Cancelled */}
                                <i className="icon icon-cancel text-4xl text-black"></i>
                            </div>
                            <span className="font-semibold text-black text-sm">Cancelled</span>
                        </div>
                    </Link>
                </div>
            </div>

            {/* Services Section */}
            <div className="flex flex-col space-y-2 bg-white rounded-lg p-4 shadow-sm">
                <h2 className="font-bold text-lg  border-b border-b-dgreyQtyProduct pb-2">Services</h2>
                <div className="flex ">
                    <Link to={`${path}/account/memberShip`} className="flex flex-col items-center justify-center h-24  rounded-lg transition duration-200 hover: p-2">
                        <div className="flex justify-center items-center w-10 h-10 rounded-full  mb-1">
                            <FaTv className="text-2xl text-black" />
                        </div>
                        <span className="font-semibold text-black text-sm">Membership</span>
                    </Link>
                    <Link to={`${path}/account/wallet`} className="flex flex-col items-center justify-center h-24  rounded-lg transition duration-200 hover: p-2">
                        <div className="flex justify-center items-center w-10 h-10 rounded-full  mb-1">
                            <FaWallet className="text-2xl text-black" />
                        </div>
                        <span className="font-semibold text-black text-sm">Wallet</span>
                    </Link>
                    <Link to={`${path}/account/coupons`} className="flex flex-col items-center justify-center h-24  rounded-lg transition duration-200 hover: p-2">
                        <div className="flex justify-center items-center w-10 h-10 rounded-full  mb-1">
                            <RiCoupon3Fill className="text-2xl text-black" />
                        </div>
                        <span className="font-semibold text-black text-sm">Coupons</span>
                    </Link>
                    <Link to={`${path}/account/checkin`} className="flex flex-col items-center justify-center h-24  rounded-lg transition duration-200 hover: p-2">
                        <div className="flex justify-center items-center w-10 h-10 rounded-full  mb-1">
                            <FaCheckCircle className="text-2xl text-black" />
                        </div>
                        <span className="font-semibold text-black text-sm">Checkin</span>
                    </Link>
                </div>
            </div>

            {/* Feedback Section */}
            <div className="flex flex-col space-y-2 bg-white rounded-lg p-4 shadow-sm">
                <h2 className="font-bold text-lg  border-b pb-2 border-b-dgreyQtyProduct">Feedback</h2>
                <div className="flex justify-start">
                    <Link to={`${path}/account/feedback`} className="flex flex-col items-center justify-center h-24  rounded-lg transition duration-200 hover: p-2">
                        <div className="flex justify-center items-center w-10 h-10 rounded-full  mb-1">
                            <MdFeedback className="text-2xl text-black" />
                        </div>
                        <span className="font-semibold text-black text-sm">Feedback</span>
                    </Link>
                </div>
            </div>

            {/* Wishlist and Recently Viewed Section */}
            <div className="flex flex-col space-y-2 bg-white rounded-lg p-4 shadow-sm">
                <h2 className="font-bold text-lg  border-b pb-2 border-b-dgreyQtyProduct">Product & Review</h2>
                <div className="flex">
                    <Link to={`${path}/account/wishlist`} className="flex flex-col items-center justify-center h-24  rounded-lg transition duration-200 hover: p-2">
                        <div className="flex justify-center items-center w-10 h-10 rounded-full  mb-1">
                            <i className="icon icon-heart text-2xl text-black"></i>
                        </div>
                        <span className="font-semibold text-black text-sm">Wishlist</span>
                    </Link>
                    <Link to={`${path}/account/recentlyViewed`} className="flex flex-col items-center justify-center h-24  rounded-lg transition duration-200 hover: p-2">
                        <div className="flex justify-center items-center w-10 h-10 rounded-full  mb-1">
                            <MdAvTimer className="text-2xl text-black" />
                        </div>
                        <span className="font-semibold text-black text-sm whitespace-nowrap">Recently Viewed</span>
                    </Link>
                    <Link to={`${path}/account/buyagain`} className="flex flex-col items-center justify-center h-24  rounded-lg transition duration-200 hover: p-2">
                        <div className="flex justify-center items-center w-10 h-10 rounded-full  mb-1">
                            <BsFillCartCheckFill className="text-2xl text-black" />
                        </div>
                        <span className="font-semibold text-black text-sm">Buy Again</span>
                    </Link>
                    <Link to={`${path}/account/reviewCenter`} className="flex flex-col items-center justify-center h-24  rounded-lg transition duration-200 hover: p-2">
                        <div className="flex justify-center items-center w-10 h-10 rounded-full  mb-1">
                            <BsStarFill className="text-2xl text-black" />
                        </div>
                        <span className="font-semibold text-black text-sm">Review</span>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Account;

import { useContext, useEffect, useState } from "react";
import UserSidebar from "../components/UserSidebar";
import _axios from "../axios";
import buildLink from "../urls";
import { useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AccountContext } from "../contexts/AccountContext";
import { useWindowDimensions } from "../components/TopHeader";
import UserSidebarMobile from "../components/UserSidebarMobile";
import Loader from "../components/PointsLoader";
import SeoHandler from "../components/SeoHandler";
import "react-step-progress/dist/index.css";
import { sanitizeHTML } from "../Utils";
import { FaQuestionCircle } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { HiStar } from "react-icons/hi";
import expressImg from "../assets/images/express.png";
function CheckIn() {
  const { width } = useWindowDimensions();

  const [showPopup, setShowpopup] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const history = useNavigate();
  const [state, dispatch] = useContext(AccountContext);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [checkins, setCheckins] = useState([]);
  const [points, setPoints] = useState(0);
  const [earnedPoint, setEarnedPoint] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [recommended, setRecommended] = useState([]);

  const modal = useRef(null);

  const getCheckinInf = () => {
    const page = searchParams.get("page");
    let pageparam = "";
    if (page) {
      pageparam = "&page=" + page;
      setPage(page);
    } else {
      setPage(0);
    }
    console.log(page);
    setLoading(true);
    _axios
      .get(
        buildLink("checkin", undefined, window.innerWidth) +
        pageparam +
        "&limit=" +
        limit
      )
      .then((response) => {
        setLoading(false);
        if (response.data.data.pop_up.checked_in) {
          setEarnedPoint(response.data.data.pop_up.checked_points);
          setShowpopup(true);
        }
        setCheckins(response.data.data.checkins);
        setPoints(response.data.data.points);
        _axios
          .get(buildLink("getRecommandedPoints") + pageparam + "&limit=" + 10)
          .then((resp) => {
            setRecommended(resp.data.data.suggestions.products);
            setTotalPage(resp.data.data.suggestions.total_pages);
          });
      });
  };

  function pageSetter(page) {
    setPage(page["selected"] + 1);
    history(`/account/checkin?&page=${page["selected"] + 1}&limit=${limit}`);
  }
  useEffect(() => {
    if (!state.loading && !state.loged) {
      history("/");
    } else if (state.loged) {
      getCheckinInf();
    }
  }, [state.loading, location]);

  return (
    <div className="container text-dblack">
      <SeoHandler
        data={{
          title: "Check In",
        }}
      />
      <div className="pb-2">
        <div className="flex-row md:flex">
          <div className={`w-full mb-4 md:w-[25%]  -ml-3 ${width <= 650 ? 'hidden' : ''}`}>
            <UserSidebar active={"checkin"} />
          </div>

          {loading ? (
            <div className=" mx-auto flex justify-center w-fit">
              <Loader />
            </div>
          ) : (
            <div className="flex mx-3 md:mx-6  overflow-hidden bg-white h-fit  md:w-full  ">
              <div className="w-full   md:px-0    mb-5 ">
                <div>
                  <div className="relative  w-full   justify-center  flex flex-col ">
                    <div className=" max-md:text-left  max-md:justify-start max-md:mx-0 max-md:ml-3 flex my-8 justify-center text-center  flex-col w-fit mx-auto">
                      <h1 className=" text-dblackk  text-3xl font-[900] ">
                        MY POINTS
                      </h1>
                      <Link
                        to={"/account/memberShip"}
                        className="  underline text-sm text-dlabelColor flex flex-row gap-2 justify-center"
                      >
                        {" "}
                        Learn more about ishtari points{" "}
                        <span className=" h-fit my-auto">
                          {" "}
                          <FaQuestionCircle className=" text-dbase text-sm" />
                        </span>{" "}
                      </Link>
                    </div>

                    <div className=" w-full h-32 bg-dgrey rounded-md">
                      <div className=" flex  flex-row text-center justify-center max-md:px-8 px-24  py-7 ">
                        <h2 className="  text-dlabelColor">
                          {" "}
                          <span className=" text-dbase text-5xl">
                            {points}
                          </span>{" "}
                          Points
                        </h2>
                        {/* <div className=" flex flex-col "><span>|</span><span>|</span><span>|</span></div>
                  <h2  className=" text-dlabelColor"> <span className="text-dbase text-5xl">0</span> US Dollars</h2> */}
                      </div>
                    </div>

                    <p className=" my-5 text-sm text-dlabelColor flex flex-row gap-2 justify-center">
                      {" "}
                      Check in every day to get points gift{" "}
                    </p>

                    <div className="  max-md:gap-5 gap-10 justify-center w-full h-full flex flex-wrap py-5">
                      {checkins &&
                        checkins.map((checkin) => {
                          return (
                            <div className=" flex-col justify-center gap-5 ">
                              <div
                                className={` cursor-cell flex justify-center text-center w-12 h-12 rounded-full ${checkin.checked_in
                                  ? " bg-dbase text-white"
                                  : " bg-dplaceHolder"
                                  } relative  text-dblack `}
                              >
                                <p className=" my-auto  mx-auto">
                                  {checkin.point}
                                </p>
                              </div>
                              <div className=" text-sm w-fit mx-auto text-dlabelColor">
                                {checkin.date}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    {recommended && (
                      <div className=" flex flex-col  container">
                        <div className="pr-semibold text-d20 text-dblack my-6">
                          Recomended
                        </div>
                        <div className=" grid grid-cols-1 md:grid-cols-2  gap-3 ">
                          {recommended.map((item, index) => {
                            return (
                              <Link
                                className="rounded-lg p-4 bg-white relative"
                                style={{
                                  height: "170px",
                                  border: "solid 1px #e6e6e6",
                                  boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.05)",
                                }}
                                to={`/${item.name
                                  .replaceAll(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                                  .replaceAll("%", "")
                                  .replaceAll(/\s+/g, "-")
                                  .replaceAll("..", "")
                                  .replaceAll("/", "-")
                                  .replaceAll("---", "-")
                                  .replaceAll("--", "-")
                                  .replaceAll("100%", "")
                                  .replaceAll("#", "")
                                  .replaceAll("/", "")}/p=${item.product_id}`}
                              >
                                <div className="flex items-center gap-2 h-">
                                  <div
                                    className="image-container mr-3.5 relative flex justify-center items-center"
                                    style={{ height: "138px", width: "92px" }}
                                  >
                                    <div className="w-full flex justify-center">
                                      <img
                                        src={item.popup}
                                        className="w-full"
                                        width={92}
                                        height={138}
                                        alt={item.name}
                                        style={{
                                          backgroundImage: `url(${"/images/product_placeholder.png"})`,
                                          height: "auto", // Set the desired height
                                          width: "100%", // Set the desired width
                                          backgroundSize: "cover",
                                          backgroundRepeat: "no-repeat",
                                          backgroundPosition: "center",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="product-info-container w-3/4">
                                    <div className="text-d14">
                                      {" "}
                                      <span
                                        className={`text-dblack `}
                                        dangerouslySetInnerHTML={{
                                          __html: item.name,
                                        }}
                                      />
                                    </div>
                                    <div className="">
                                      <div>
                                        <strong className="pr-bold text-d16">
                                          {item.special !== "0" &&
                                            item.special !== "" &&
                                            item.special !== false
                                            ? item.special
                                            : item.price}
                                        </strong>
                                      </div>
                                      <div
                                        className={`mt-0.5 text-d12 flex items-center ${(item.special === "0" ||
                                          item.special === "" ||
                                          item.special === false) &&
                                          "invisible"
                                          }`}
                                      >
                                        <div
                                          className={`oldPrice text-d13 line-through text-dgreyProduct mr-1.5 font-normal`}
                                        >
                                          {item.price}
                                        </div>
                                        <div className=" text-xs  font-normal  py-0  border border-dbase  rounded-md px-2  text-dbase   flex justify-center  whitespace-nowrap">
                                          <span className=" text-center my-auto">
                                            -{item.saving} %{" "}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="product-card-footer flex flex-col gap-4 mt-4"></div>
                                      <div
                                        className="splitter w-full"
                                        style={{
                                          height: "1px",
                                          backgroundColor: "#E6E6E6",
                                        }}
                                      ></div>
                                      <div className={`flex`}>
                                        <div
                                          className="mt-3 flex flex-row gap-7 items-center justify-start w-full"
                                          style={{ minHeight: "16px" }}
                                        >
                                          <div className="express  -mt-1">
                                            {item.market === "0" ? (
                                              <img
                                                width={64}
                                                height={24}
                                                src={expressImg}
                                                className="h-6 w-16 py-1 mobile:py-0 lg:py-1"
                                                alt="Express delivery"
                                              />
                                            ) : (
                                              <img
                                                width={64}
                                                height={24}
                                                src={"/images/market.svg"}
                                                className="h-6 w-16 py-1 mobile:py-0 lg:py-1 "
                                                alt={"market image"}
                                              />
                                            )}
                                          </div>
                                          {item?.nb_of_reviews > 0 && (
                                            <div className=" flex ">
                                              <div
                                                className="flex rounded-full  place-content-end h-4  align-bottom pl-1"
                                                style={{
                                                  backgroundColor:
                                                    item?.rating >= 4.5
                                                      ? "rgb(0,158,0)"
                                                      : item?.rating < 4.5 &&
                                                        item?.rating >= 4
                                                        ? "rgb(110, 159, 0)"
                                                        : item?.rating < 4 &&
                                                          item?.rating >= 3.5
                                                          ? "rgb(243, 153, 22)"
                                                          : "rgb(246,90,31)",
                                                }}
                                              >
                                                <div
                                                  className="text-d11 font-bold text-white"
                                                  style={{
                                                    paddingTop: "0.5px",
                                                  }}
                                                >
                                                  {item?.rating || "0.0"}
                                                </div>
                                                <HiStar className="pt-1 text-white text-bold text-d12" />{" "}
                                              </div>

                                              <div className="font-light text-d11 pl-0.5">
                                                ({" "}
                                                {item?.reviews?.length < 1 &&
                                                  item?.reviews?.length === ""
                                                  ? "0"
                                                  : item?.nb_of_reviews}{" "}
                                                )
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            );
                          })}
                        </div>
                        {totalPage > 0 && (
                          <ReactPaginate
                            pageCount={Math.ceil(totalPage / limit)}
                            containerClassName={"pagination"}
                            onPageChange={pageSetter}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            previousLabel={"<"}
                            nextLabel={">"}
                            activeClassName={"active-pagination"}
                            forcePage={page ? parseInt(page) - 1 : 0}
                          ></ReactPaginate>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {showPopup && (
        <div
          onClick={() => setShowpopup(false)}
          className=" fixed left-0 top-0 right-0 bottom-0  bg-dblack bg-opacity-40 z-50 "
        ></div>
      )}
      {showPopup && (
        <div
          onClick={() => setShowpopup(false)}
          className=" fixed left-0 top-0 right-0 bottom-0  bg-dblack bg-opacity-40 z-50 "
        ></div>
      )}
      <div
        className={`${showPopup
          ? " top-0  scale-100 opacity-100"
          : " -top-32 scale-0 opacity-0"
          } z-50 fixed px-5   rounded-md max-md:w-[350px] max-md:h-[300px] left-0 top-0 bottom-0 py-5 right-0  transition-opacity duration-300 bg-white my-auto mx-auto w-[450px] h-[350px]`}
      >
        <div className=" w-full h-full relative justify-center text-center flex flex-col gap-4">
          <div className=" w-16 h-16  mx-auto   bg-dbase text-white rounded-full flex items-center justify-center">
            <span className=" text-2xl">+1</span>
          </div>
          <h2 className=" text-2xl font-bold">
            You've earned{" "}
            <span className=" text-dbase  font-extrabold">{earnedPoint}</span>{" "}
            points!
          </h2>
          <span className=" text-md  text-dlabelColor">
            Check in for 2 more days to get a surprise reward.
          </span>
          <button
            onClick={() => setShowpopup(false)}
            className="  bg-dbase mt-4 text-white px-4 py-1 rounded-md text-xl"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default CheckIn;
